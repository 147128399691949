import { atom } from 'recoil'
import { FlipIds } from '../constants.ts/constants'

export const flipOptionsState = atom({
  key: 'flipOptionsState',
  default: { [FlipIds.FparRiskRadar]: { index: 0 } } as { [key: string]: { index: number; update?: boolean } },
})

export const cardDimensionsState = atom({
  key: 'cardDimensionsState',
  default: { height: 450, width: 450 } as {
    height: number
    width: number
  },
})
