import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Answer, Question, QuestionOption } from '../../store/questions'
import { getPossibleAnswersApi, setPossibleAnswersApi, setQuestionApi } from '../../api/questionsApi'
import { useQuestionsApi } from '../../hooks/api/useQuestionsApi'
import { CircleLoader } from 'react-spinners'
import { CircularLoader } from '../Widgets/Loaders/CicularLoader'

interface Props {
  question: Question
  damId: number
  answer: Answer
}

export const DialogEditQuestion: React.FC<Props> = ({ question, answer, damId }) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [possibleAnswers, setPossibleAnswers] = useState<QuestionOption[]>()
  const [questionEdited, setQuestionEdited] = useState<Question>(question)
  const { getQuestions } = useQuestionsApi()

  useEffect(() => {
    edit && question && setQuestionEdited(question)
  }, [question, edit])

  useEffect(() => {
    const loadPossibleQuestions = async () => {
      getPossibleAnswersApi(String(question.id)).then(response => setPossibleAnswers(response.data?.possibleAnswers))
    }
    edit && question && loadPossibleQuestions()
  }, [question, edit])

  const save = async () => {
    setIsLoading(true)
    const questionResponse = setQuestionApi(questionEdited)
    const possibleAnswResponse = setPossibleAnswersApi(String(questionEdited.id), possibleAnswers)

    await Promise.all([questionResponse, possibleAnswResponse])
    await getQuestions()
    setEdit(false)
    setIsLoading(false)
  }

  return (
    <div style={{ position: 'absolute', right: '-45px', top: '12px' }}>
      <IconButton onClick={() => setEdit(true)}>
        <Edit />
      </IconButton>
      <Dialog onClose={() => setEdit(false)} open={edit} fullWidth>
        <DialogTitle>Editing Question</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              label={'Edit question Description here:'}
              name={`question-label-${questionEdited.id}`}
              value={questionEdited.questionText}
              onChange={e => setQuestionEdited({ ...questionEdited, questionText: e.target.value })}
              //onBlur={handleAnswerSave(answer)}
            />
          </FormControl>
          <h3>Editing Possible Answers</h3>
          {Array.isArray(possibleAnswers) &&
            possibleAnswers.map((possibleAnswer, index) => (
              <div style={{ padding: '15px', border: '1px solid #997dd9', borderRadius: '5px', marginBottom: '20px' }}>
                <FormControl fullWidth>
                  <TextField
                    label={'Descriptor'}
                    name={`possible-answer-${possibleAnswer.id}`}
                    value={possibleAnswer.descriptor}
                    onChange={e => {
                      const newPossibleAnswers = [...possibleAnswers]
                      newPossibleAnswers[index] = { ...possibleAnswers[index], descriptor: e.target.value }
                      setPossibleAnswers(newPossibleAnswers)
                    }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    label={'Value'}
                    name={`possible-answer-${possibleAnswer.id}`}
                    value={possibleAnswer.value}
                    onChange={e => {
                      const newPossibleAnswers = [...possibleAnswers]
                      newPossibleAnswers[index] = { ...possibleAnswers[index], value: e.target.value }
                      setPossibleAnswers(newPossibleAnswers)
                    }}
                  />
                </FormControl>
              </div>
            ))}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button disabled={isLoading} variant="contained" color="secondary" onClick={save}>
              {isLoading && <CircularProgress style={{ width: 20, height: 20 }} />}
              {!isLoading && 'Save'}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
