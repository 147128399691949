import { createStyles, ListItemIcon, makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    whiteListItemIcon: {
      color: theme.palette.primary.light,
    },
  })
)

interface Props {
  className?: string
}

export const WhiteListItemIcon: React.FC<Props> = ({ children, className }) => {
  const classes = useStyles()
  return <ListItemIcon className={classNames(className, classes.whiteListItemIcon)}>{children}</ListItemIcon>
}
