import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

const CardTitle = styled(Typography)`
  display: inline-block;
  width: 100%;
  text-align: center;
`

interface Props {
  style: Record<string, string | number>
}

export const TitleCard: React.FC<Props> = ({ children, style }) => (
  <CardTitle style={style} variant="h4" color="secondary">
    {children}
  </CardTitle>
)
