import axios from 'axios'
import { GraphItem } from '../store/graphs'
import { getAccessToken } from './microsoft/useGetAccessToken'
export const GRAPHS_URL = '/graphs'

export interface GraphsResponse {
  allFacilitiesResults: GraphItem[]
}

export const getGraphsApi = async () =>
  axios.get<GraphsResponse>(`${process.env.REACT_APP_API_URL}${GRAPHS_URL}`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
  })
