import { useEffect, useState } from 'react'
import { useDamsWithResults } from './useDamsWithResults'

export const useAreDamResultsReady = () => {
  const { enhancedDams } = useDamsWithResults()

  console.log('DAMS', enhancedDams);

  const [ready, setReady] = useState(false)
  useEffect(() => {
    let show = false
    enhancedDams.forEach(dam => {
      show = !!dam.pof.totalPof
    })
    setReady(show)
  }, [enhancedDams, setReady])

  return ready
}
