import { LeftTopToolbarLayout } from '../../Components/Layout/LeftTopToolbarLayout'
import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { FparRiskRadarChart } from '../../Components/charts/FparRiskRadarChart'
import { selectedDamIdState } from '../../store/dams'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useSpring, animated } from 'react-spring'
import { SelectedDamGrid } from '../../Components/DataGrids/SelectedDamGrid'
import { ZoomableDamTreemapApex } from '../../Components/charts/ZoomableDamTreemapApex'
import { FlipChart } from '../../Components/charts/common/FlipChart'
import { FparPieChart } from '../../Components/charts/FparPieChart'
import { flipOptionsState } from '../../store/layout'
import { FlipIds } from '../../constants.ts/constants'
import { GridContainer } from '../../Components/common/styled'
import { DialogFrame } from '../../Components/charts/common/DialogFrame'
// @ts-ignore
import LeaderLine from 'react-leader-line'
import { DamMapWithActions, damMapWithActionsSpringConfig } from '../../Components/Widgets/DamMap/DamMapWithActions'
import { MapTopActions } from '../../Components/Dashboard/MapTopActionts'

import { useAreDamResultsReady } from '../../hooks/useAreDamResultsReady'
import { useGraphsApi } from '../../hooks/api/useGraphsApi'
import { useDamsApi } from '../../hooks/api/useDamsApi'
import { FparRiskSummaryChart } from '../../Components/charts/FparRiskSummaryChart'
import { ImageCard } from '../../Components/common/ImageCard'
import { FparDollar3D } from '../../Components/charts/FparDollar3D'
import fNPlot from '../../assets/fNPlot.png'
import fNPlotS from '../../assets/fNPlotS.png'
import fparPlot from '../../assets/fparPlot.png'
import fparPlotS from '../../assets/fparPlotS.png'
import cumulativeDollarPlot from '../../assets/fNPlot.png'
import cumulativeDollarPlotS from '../../assets/cumulativeDollarPlotS.png'
import cumulativeFnPlot from '../../assets/cumulativeFnPlot.png'
import cumulativeFnPlotS from '../../assets/cumulativeFnPlotS.png'

export const Dashboard = () => {
  const selectedDamIds = useRecoilValue(selectedDamIdState)
  const [resetAnimationHelper, setResetAnimationHelper] = React.useState<number>(0)
  const [flippedState, setFlippedState] = useRecoilState(flipOptionsState)
  const { getGraphs } = useGraphsApi()
  const { getDams } = useDamsApi()

  React.useEffect(() => {
    if (selectedDamIds.length > 0) {
      flippedState[FlipIds.FparRiskRadar]?.index !== 1 &&
        setFlippedState({ ...flippedState, [FlipIds.FparRiskRadar]: { index: 1, update: true } })
      setResetAnimationHelper(resetAnimationHelper + 1)
    }
    if (selectedDamIds.length === 0) {
      flippedState[FlipIds.FparRiskRadar]?.index !== 0 &&
        setFlippedState({ ...flippedState, [FlipIds.FparRiskRadar]: { index: 0, update: true } })
      setResetAnimationHelper(resetAnimationHelper + 1)
    }
  }, [selectedDamIds])

  const springProps = useSpring(damMapWithActionsSpringConfig)

  useEffect(() => {
    getGraphs()
    getDams()
  }, [])

  const damResultsReady = useAreDamResultsReady()

  return (
    <LeftTopToolbarLayout>
      <GridContainer style={{ overflow: 'hidden' }}>
        <Grid container spacing={1}>
          <Grid item sm={9}>
            <SelectedDamGrid />
          </Grid>
          <Grid item sm={3}>
            <animated.div style={springProps}>
              <DamMapWithActions top={<MapTopActions />} />
            </animated.div>
          </Grid>
        </Grid>
        <div style={{ height: 22 }} />
        {damResultsReady && (
          <Grid container spacing={1}>
            <Grid item sm={4}>
              <DialogFrame>
                <FlipChart
                  id={FlipIds.ZoomPieRisk}
                  pages={[<ZoomableDamTreemapApex />, <FparPieChart />, <FparRiskSummaryChart />]}
                />
              </DialogFrame>
            </Grid>
            {/*
          , <FparPieChart />, <FparRiskSummaryChart />*/}
            <Grid item sm={4}>
              <DialogFrame>
                <FlipChart
                  id={FlipIds.FparRiskRadar}
                  pages={[<FparRiskSummaryChart />, <FparRiskRadarChart key={resetAnimationHelper} />]}
                />
              </DialogFrame>
            </Grid>

            <Grid item sm={4}>
              <DialogFrame>
                <FlipChart
                  id={FlipIds.ScatterRisk}
                  // pages={[<ScatterRiskChart />, <ScatterCofRiskChart />, <FparDollar3D />]}
                  pages={[
                    <ImageCard src={fparPlot} srcSmall={fparPlotS} />,
                    <ImageCard src={fNPlot} srcSmall={fNPlotS} />,
                    <ImageCard src={cumulativeDollarPlot} srcSmall={cumulativeDollarPlotS} />,
                    <ImageCard src={cumulativeFnPlot} srcSmall={cumulativeFnPlotS} />,
                    <FparDollar3D />,
                  ]}
                  hiddenOverflow
                />
              </DialogFrame>
            </Grid>
          </Grid>
        )}
      </GridContainer>
    </LeftTopToolbarLayout>
  )
}
