import { Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

interface Props {
  active?: boolean
  payload?: any
  label?: string
}

const LegendTooltipLabel = styled.span``
const LegendTooltipValue = styled.span`
  ${({ color }) =>
    color &&
    `
  color: ${color};
`}
`
const LegendTooltipValueLabel = styled.span`
  ${({ color }) =>
    color &&
    `
    color: ${color};
  `}
`
const LegendTooltipValueWrapper = styled.span`
  ${({ color }) =>
    color &&
    `
  background: ${color}40;
  border-radius: 5px;
`}
  padding: 2px 4px;
`
const LegendTooltipWrapper = styled(Paper)`
  padding: 16px;
  max-width: 200px;
  border: 1px dashed #3b21756b;
`

export const ScientificTooltip: React.FC<Props> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <LegendTooltipWrapper>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LegendTooltipLabel>{label} </LegendTooltipLabel>
          </Grid>
          {payload.map((p: any, index:number) => (
            <Grid key={index} item xs={12}>
              <LegendTooltipValueWrapper color={p.color}>
                <LegendTooltipValueLabel color={p.color}>{p.name}: </LegendTooltipValueLabel>
                <LegendTooltipValue color={p.color}>{p.value.toExponential(2)} </LegendTooltipValue>
              </LegendTooltipValueWrapper>
            </Grid>
          ))}
        </Grid>
      </LegendTooltipWrapper>
    )
  }

  return null
}
