import { atom, selector } from 'recoil'

export interface Gistm {
  facilityId: string
  topic1: number
  topic2: number
  topic3: number
  topic4: number
  topic5: number
  topic6: number
}

export interface GistmWithFacilityName extends Gistm {
  name: string
}

const data: Gistm[] = [
  {
    facilityId: '1',
    topic1: 4,
    topic2: 3,
    topic3: 3,
    topic4: 2,
    topic5: 2,
    topic6: 4,
  },
  {
    facilityId: '2',
    topic1: 1,
    topic2: 3,
    topic3: 1,
    topic4: 4,
    topic5: 4,
    topic6: 4,
  },
  {
    facilityId: '3',
    topic1: 3,
    topic2: 2,
    topic3: 2,
    topic4: 1,
    topic5: 1,
    topic6: 2,
  },
  {
    facilityId: '4',
    topic1: 1,
    topic2: 4,
    topic3: 4,
    topic4: 4,
    topic5: 4,
    topic6: 3,
  },
  {
    facilityId: '5',
    topic1: 2,
    topic2: 4,
    topic3: 3,
    topic4: 4,
    topic5: 2,
    topic6: 2,
  },
  {
    facilityId: '6',
    topic1: 2,
    topic2: 3,
    topic3: 3,
    topic4: 3,
    topic5: 3,
    topic6: 3,
  },
]

export const gistmState = atom({
  key: 'gistmState',
  default: data as Gistm[],
})

export const selectedGistmIdState = atom({
  key: 'selectedGistmIds',
  default: [] as string[],
})

export const lastSelectedGistmId = atom({
  key: 'lastSelectedGistmId',
  default: null as string | null,
})

export const selectedGistms = selector({
  key: 'selectedGistms',
  get: ({ get }) => {
    const selectedGistmIds = get(selectedGistmIdState)
    return get(gistmState).filter(gistm => selectedGistmIds.find(dId => dId === gistm.facilityId))
  },
})
