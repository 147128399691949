import { createMuiTheme } from '@material-ui/core'
import arrayShuffle from 'array-shuffle'
import { scaleOrdinal } from 'd3-scale'
import { schemeSet2 } from 'd3-scale-chromatic'

export const themeObject = {
  palette: {
    primary: {
      main: '#3B2175',
      lighter10: '#552FA1',
    },
    secondary: {
      main: '#9C8BD9',
      darklight: '#E6D815',
      mustsee: '#C6BDE9',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      //secondary: 'rgba(255, 255, 255, 0.7)',
      //disabled: 'rgba(255, 255, 255, 0.5)',
      white: 'white'
    },
    charts: [],
  },
  typography: {
    fontSize: 11,
    fontFamily:
      'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  },
}

themeObject.palette = {
  ...themeObject.palette,
  charts: [
    themeObject.palette.primary.lighter10,
    themeObject.palette.secondary.main,
    themeObject.palette.secondary.mustsee,
    themeObject.palette.secondary.darklight,
    ...arrayShuffle(scaleOrdinal(schemeSet2).range()),
  ] as any,
}

export const muiTheme = createMuiTheme(themeObject)
