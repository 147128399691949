import { atom } from 'recoil'

export interface Chapter {
  id: number
  name: string
}

export interface Subchapter {
  id: number
  chapterId: number
  name: string
}

export enum QuestionTypes {
  TEXT = 'TEXT',
  ONE_OF = 'ONE_OF',
  NUMBER = 'NUMBER',
  RANGE = 'RANGE',
}

export interface QuestionOption {
  id: number
  descriptor?: string
  value: any
}

export interface Question {
  id: number
  chapterId: number
  subChapterId: number
  originalQuestionId: number
  questionText: string
  type: QuestionTypes
  options: QuestionOption[]
}

export interface Answer {
  damId: number
  questionId: number
  choiceId: number | null
  value: string | null
}

export const createEmptyAnswer = (questionId: number, damId: number): Answer => ({
  damId,
  questionId,
  choiceId: null,
  value: '',
})

export const chapterState = atom({
  key: 'chapterState',
  default: [] as Chapter[],
})

export const questionState = atom({
  key: 'questionState',
  default: [] as Question[],
})

export const subchapterState = atom({
  key: 'subchapterState',
  default: [] as Subchapter[],
})

export const answersState = atom({
  key: 'answersState',
  default: [] as Answer[],
})
