import { themeObject } from './../../../muiTheme'
import { makeStyles, Theme, createStyles } from '@material-ui/core'
import styled from 'styled-components'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 258,
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  })
)

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background: ${themeObject.palette.primary.main};
  display: flex;
  flex-direction: column;
`
