import { getAnswersApi, getChaptersApi, getQuestionsApi, getSubChaptersApi, setAnswerApi } from '../../api/questionsApi'
import { useSetRecoilState } from 'recoil'
import { Answer, answersState, chapterState, questionState, subchapterState } from '../../store/questions'

export const useQuestionsApi = () => {
  const setChapters = useSetRecoilState(chapterState)
  const setSubchapters = useSetRecoilState(subchapterState)
  const setQuestions = useSetRecoilState(questionState)
  const setAnswers = useSetRecoilState(answersState)

  const getChapters = () =>
    getChaptersApi()
      .then(response => {
        if (Array.isArray(response.data)) {
          setChapters(response.data)
        }
      })
      .catch(e => {
        console.error(e)
      })

  const getSubchapters = () =>
    getSubChaptersApi()
      .then(response => {
        if (Array.isArray(response.data)) {
          setSubchapters(response.data)
        }
      })
      .catch(e => {
        console.error(e)
      })

  const getQuestions = () =>
    getQuestionsApi()
      .then(response => {
        if (Array.isArray(response.data)) {
          setQuestions(response.data)
        }
      })
      .catch(e => {
        console.error(e)
      })

  const getAnswers = (id: string, cleanupData?: boolean) => {
    cleanupData && setAnswers([])
    getAnswersApi(id)
      .then(response => {
        if (Array.isArray(response.data)) {
          setAnswers(response.data)
        }
      })
      .catch(e => {
        console.error(e)
      })
  }

  const setAnswer = (answer: Answer, facilityId: number) => {
    setAnswerApi(answer, facilityId)
      .then(response => {
        if (Array.isArray(response.data)) {
          setAnswers(response.data)
        }
      })
      .catch(e => {
        console.error('setAnswer - error:', e)
      })
  }

  return {
    getChapters,
    getSubchapters,
    getQuestions,
    getAnswers,
    setAnswer,
  }
}
