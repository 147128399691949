import { useEffect } from 'react'
import { Switch, Route, useLocation, useHistory } from 'react-router-dom'
import { Login } from './Pages/Login/Login'
import { Dashboard } from './Pages/Dashboard/Dashboard'
import { Gistm } from './Pages/Gistm/Gistm'
import { useSetRecoilState } from 'recoil'
import { routerPathState } from './store/router'
import { LandingMap } from './Pages/LandingMap/LandingMap'
import { CriticalControlsDashboard } from './Pages/CriticalControlsDashboard/CriticalControlsDashboard'
import { EventTree } from './Pages/EventTree/EventTree'
import { RiskScenarioTool } from './Pages/RiskScenarioTool/RiskScenarioTool'
import axios from 'axios'
import { Questions } from './Pages/Questions/Questions'
import { Facilities } from './Pages/Facilities/Facilities'
import { useIsAuthenticated } from '@azure/msal-react'

export enum Pages {
  Home = '/',
  fC = '/f-c',
  CriticalControls = '/critical-controls',
  EventTree = '/event-tree',
  GISTM = '/gistm',
  D5 = '/d5',
  ANCOLD = '/ancold',
  LandingMap = '/landing-map',
  RskScenarioTool = '/risk-scenario-tool',
  DamCharacteristicsQuestions = '/dam-characteristics',
  Facilities = '/facilities',
}

export const AppRouter = () => {
  const location = useLocation()
  const history = useHistory()
  const setRouterPath = useSetRecoilState(routerPathState)

  useEffect(() => {
    setRouterPath(location.pathname)
  }, [location])

  const isAuthenticated = useIsAuthenticated()
  useEffect(() => {
    if (isAuthenticated) {
      if (location.pathname.length <= 2) {
        history.push(Pages.fC)
      }
    }
  }, [isAuthenticated])

  axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*'

  return (
    <Switch>
      {/* <Route path={Pages.fC} component={Dashboard} />
      <Route path={Pages.EventTree} component={EventTree} />
      <Route path={Pages.GISTM} component={Gistm} />
      <Route path={Pages.D5} component={LandingMap} />
      <Route path={Pages.ANCOLD} component={Dashboard} />
      <Route path={Pages.LandingMap} component={LandingMap} />
      <Route path={Pages.RskScenarioTool} component={RiskScenarioTool} />
      <Route path={Pages.DamCharacteristicsQuestions} component={Questions} />
      <Route path={Pages.Home} component={Login} /> */}
      <Route path={Pages.fC} component={Dashboard} />
      <Route path={Pages.CriticalControls} component={CriticalControlsDashboard} />
      <Route path={Pages.EventTree} component={Questions} />
      <Route path={Pages.GISTM} component={Gistm} />
      <Route path={Pages.D5} component={Questions} />
      <Route path={Pages.ANCOLD} component={Questions} />
      <Route path={Pages.LandingMap} component={Questions} />
      <Route path={Pages.RskScenarioTool} component={Questions} />
      <Route path={Pages.DamCharacteristicsQuestions} component={Questions} />
      <Route path={Pages.Facilities} component={Facilities} />
      <Route path={Pages.Home} component={Login} />
    </Switch>
  )
}
