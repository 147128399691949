import React, { useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { ArrowBack } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import ReactApexChart from 'react-apexcharts'
import { themeObject } from 'src/muiTheme'
import { selectedDamIdState } from '../../store/dams'
import { DialogFrameChildrenProps } from './common/DialogFrame'
import { adjustedHeightConstant } from '../Layout/BasicLayoutComponents'
import { useDamsWithResults } from '../../hooks/useDamsWithResults'

const initialTreemapSettings = {
  enableShades: false,
  distributed: true,
}

const intialTitle = 'f-PAR Risk Summary'

export const ZoomableDamTreemapApex: React.FC<DialogFrameChildrenProps> = ({ componentHeight = 350 }) => {
  const adjustedHeight = componentHeight - adjustedHeightConstant
  const { enhancedDams: enhancedDamsBeforeFilter } = useDamsWithResults()
  const selectedDamIds = useRecoilValue(selectedDamIdState)
  const damsData: any = {}

  const enhancedDams =
    selectedDamIds.length > 0
      ? selectedDamIds.map(id => ({ ...(enhancedDamsBeforeFilter.find(d => d.id == id) || {}) }))
      : enhancedDamsBeforeFilter

  const firstSelDam = useMemo(() => selectedDamIds[0] && enhancedDams.find(d => d.id == selectedDamIds[0]), [
    selectedDamIds,
  ])

  const damsSummary = [
    { name: 'f - PAR Risk Summary', data: enhancedDams.map(d => ({ x: d.name, y: d?.pof.totalPof })) },
  ]

  enhancedDams.forEach(dam => {
    damsData[dam.name] = [
      { x: 'Steady-State: Drained', y: dam?.pof.steadyStateSlopeFailureDrained?.totalPof },
      { x: 'Steady-State: Undrained', y: dam?.pof.steadyStateSlopeFailureUndrained?.totalPof },
      { x: 'Seismic', y: dam?.pof.seismic?.totalPof },
      { x: 'Overtopping', y: dam?.pof.overtopping?.totalPof },
      { x: 'Embankment piping', y: dam?.pof.embankmentPiping?.totalPof },
      { x: 'Foundation piping', y: dam?.pof.foundationPiping?.totalPof },
      { x: 'Embankment into or at foundation piping', y: dam?.pof.embankmentIntoOrAtFoundationPiping?.totalPof },
    ]
  })

  const [data, setData] = useState<any>(damsSummary)
  const [treemap, setTreemap] = useState(initialTreemapSettings)
  const [title, setTitle] = useState(intialTitle)
  const [offset, setOffset] = useState<number>(0)

  const resetData = () => {
    setData(damsSummary)
    setTreemap(initialTreemapSettings)
    setTitle(intialTitle)
    setOffset(0)
  }

  React.useEffect(() => {
    if (selectedDamIds.length === 1 && firstSelDam && damsData[firstSelDam.name]) {
      if (data[0]?.data?.id === selectedDamIds[0]) {
        return
      }
      setData([{ name: firstSelDam.name, data: damsData[firstSelDam.name] }])
      setTitle(firstSelDam.name)
      setOffset(1)
    }
    if (selectedDamIds.length !== 1) {
      resetData()
    }
  }, [firstSelDam, selectedDamIds])

  const onDamClick = (name: string) => {
    const foundRow = damsData[name]
    if (foundRow) {
      setData([{ name, data: foundRow }])
      setTreemap({
        enableShades: true,
        distributed: false,
      })
      setTitle(name)
      setOffset(1)
    }
  }

  const state = {
    series: data,
    options: {
      chart: {
        height: adjustedHeight,
        type: 'treemap',
        events: {
          click: (c: any, anychartContext: any, config: any) => {
            const damName = config?.config?.series[config.seriesIndex]?.data[config.dataPointIndex]?.x
            onDamClick(damName)
          },
        },
      },
      title: {
        text: title,
        align: 'center',
      },
      plotOptions: {
        treemap,
      },
      tooltip: {
        y: {
          formatter: (a: any) => Number(a).toExponential(2),
        },
      },
      colors: themeObject.palette.charts,
    },
  }

  return (
    <>
      {offset > 0 && (
        <IconButton style={{ position: 'absolute', zIndex: 5, left: 35 }} onClick={resetData} color="primary">
          <ArrowBack />
        </IconButton>
      )}
      <div style={{ width: '100%', height: adjustedHeight, paddingTop: 10 }}>
        <ReactApexChart
          // @ts-ignore
          options={state.options}
          series={state.series}
          type={'treemap' as any}
          height={adjustedHeight}
        />
      </div>
    </>
  )
}
