import React, { useEffect } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { damState, selectedDams, selectedDamIdState, lastSelectedDamId, damResultsStore } from '../../store/dams'
import { totalPofValueScientificFormated } from '../../Utils/scientificNotations'
import { DataGrid, GridColDef, GridRowId, GridSelectionModelChangeParams } from '@material-ui/data-grid'
import { without } from 'ramda'
import { Paper } from '@material-ui/core'
import { cardDimensionsState } from '../../store/layout'
import { useDamResult } from '../../hooks/api/useDamResult'
import { useDamsWithResults } from '../../hooks/useDamsWithResults'

export const SelectedDamGrid = () => {
  const cardDimensions = useRecoilValue(cardDimensionsState)

  const [selDamIds, setSelDamIds] = useRecoilState(selectedDamIdState)
  const setLastSelDamId = useSetRecoilState(lastSelectedDamId)

  const adjustedHeight = cardDimensions.height + 30
  // const scientificNotationDams = damsToScientificNotation(dams)



  const updateSelection = (newSelection: GridSelectionModelChangeParams) => {
    if (Array.isArray(newSelection.selectionModel) && newSelection.selectionModel.length !== selDamIds.length) {
      setSelDamIds(newSelection.selectionModel as string[])
      setLastSelDamId(without(selDamIds, newSelection.selectionModel)[0] as string)
    }
  }

  const {enhancedDams} = useDamsWithResults()
  const rows = enhancedDams

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Facility', width: 150 },
    {
      field: 'steadyStateSlopeFailureDrained',
      headerName: 'Steady-State slope failure: Drained',
      valueFormatter: totalPofValueScientificFormated,
      width: 150,
    },
    {
      field: 'steadyStateSlopeFailureUndrained',
      headerName: 'Steady-State slope failure: Undrained',
      valueFormatter: totalPofValueScientificFormated,
      width: 150,
    },
    { field: 'seismic', headerName: 'Seismic', valueFormatter: totalPofValueScientificFormated, width: 150 },
    { field: 'overtopping', headerName: 'Overtopping', valueFormatter: totalPofValueScientificFormated, width: 150 },
    {
      field: 'embankmentPiping',
      headerName: 'Embankment piping',
      valueFormatter: totalPofValueScientificFormated,
      width: 150,
    },
    {
      field: 'foundationPiping',
      headerName: 'Foundation piping',
      valueFormatter: totalPofValueScientificFormated,
      width: 150,
    },
    {
      field: 'embankmentIntoOrAtFoundationPiping',
      headerName: 'Embankment into or at foundation piping',
      valueFormatter: totalPofValueScientificFormated,
      width: 150,
    },
    {
      field: 'totalPof',
      headerName: 'Total Probability of failure',
      valueFormatter: totalPofValueScientificFormated,
      width: 150,
    },
    { field: 'par', headerName: 'PAR', valueFormatter: totalPofValueScientificFormated, width: 150 },
    {
      field: 'businessConsequence',
      headerName: 'Business Consequences (AUD MIL)',
      valueFormatter: totalPofValueScientificFormated,
      width: 150,
    },
  ]

  return (
    <Paper>
      <div style={{ height: adjustedHeight, width: '100%' }}>
        <DataGrid
          checkboxSelection={true}
          onSelectionModelChange={updateSelection}
          selectionModel={selDamIds}
          hideFooter
          rows={rows}
          columns={columns}
        />
      </div>
    </Paper>
  )
}
