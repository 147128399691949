import React, { useState } from 'react'
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { themeObject } from '../../muiTheme'
import { useRecoilValue } from 'recoil'
import { selectedGistms } from '../../store/gistm'
import { DialogFrameChildrenProps } from './common/DialogFrame'
import { adjustedHeightConstant } from '../Layout/BasicLayoutComponents'
import { useAddNameToGistms } from 'src/Utils/useAddNameToGistms'
import { Typography } from '@material-ui/core'

const colors = themeObject.palette.charts

export const GistmRadarChart: React.FC<DialogFrameChildrenProps> = ({ componentHeight = 350 }) => {
  const gistms = useRecoilValue(selectedGistms)
  const adjustedHeight = componentHeight - adjustedHeightConstant
  const namedGistms = useAddNameToGistms(gistms)
  const [highlighted, setHighlighted] = useState<undefined | string>()
  const handleMouseEnter = ({ dataKey }: any) => setHighlighted(dataKey)
  const handleMouseLeave = () => setHighlighted(undefined)
  const determineOpacity = (name: string) => {
    if (!highlighted) return 1
    if (name === highlighted) return 1
    return 0.2
  }
  const rows = [
    { name: 'Topic 1' },
    { name: 'Topic 2' },
    { name: 'Topic 3' },
    { name: 'Topic 4' },
    { name: 'Topic 5' },
    { name: 'Topic 6' },
  ] as any[]

  for (const gistm of namedGistms) {
    rows[5][gistm.name] = gistm.topic6
    rows[4][gistm.name] = gistm.topic5
    rows[3][gistm.name] = gistm.topic4
    rows[2][gistm.name] = gistm.topic3
    rows[1][gistm.name] = gistm.topic2
    rows[0][gistm.name] = gistm.topic1
  }

  return (
    <div style={{ height: adjustedHeight, width: '100%' }}>
      <Typography variant="h6" align="center">
        {'GISTM Compliance Ratings - Facility'}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={rows}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis angle={90} domain={[0, 4]} tickCount={5} />
          {namedGistms.map((gistm, index) => {
            const color = colors[index % colors.length]
            const opacity = determineOpacity(gistm.name)
            return (
              <Radar
                key={gistm.facilityId}
                isAnimationActive={true}
                name={gistm.name}
                dataKey={gistm.name}
                stroke={color}
                fill={color}
                style={{ transition: 'opacity .3s ease-in-out', opacity }}
                fillOpacity={0.6}
              />
            )
          })}
          <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
          <Tooltip />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  )
}
