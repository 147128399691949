import React from 'react'
import { Button, Grid } from '@material-ui/core'

export const MapTopActions: React.FC = () => (
  <Grid container spacing={1}>
    <Grid item xs={6}>
      <Button fullWidth variant="contained" color="secondary">Enquire</Button>
    </Grid>
    <Grid item xs={6}>
      <Button fullWidth variant="contained" color="secondary">Create Report</Button>
    </Grid>
  </Grid>
)
