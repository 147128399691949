import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { Typography } from '@material-ui/core'
import { themeObject } from '../../muiTheme'
import { useRecoilValue } from 'recoil'
import { gistmState } from '../../store/gistm'
import { DialogFrameChildrenProps } from './common/DialogFrame'
import { adjustedHeightConstant } from '../Layout/BasicLayoutComponents'
import { useAddNameToGistms } from 'src/Utils/useAddNameToGistms'

const colors = themeObject.palette.charts

const calculateAverageValues = valuesArray => {
  const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length
  const preparedTopics = {
    topic1: [],
    topic2: [],
    topic3: [],
    topic4: [],
    topic5: [],
    topic6: [],
  }
  valuesArray.map(value => {
    for (const [key, val] of Object.entries(value)) {
      preparedTopics[key]?.push(val)
    }
  })

  for (let [key, val] of Object.entries(preparedTopics)) {
    preparedTopics[key] = Math.round(average(val))
  }
  return preparedTopics
}

export const GistmAverageRadarChart: React.FC<DialogFrameChildrenProps> = ({ componentHeight = 350 }) => {
  const gistms = useRecoilValue(gistmState)
  const adjustedHeight = componentHeight - adjustedHeightConstant
  const namedGistms = useAddNameToGistms(gistms)
  const portfolioAverage = 'Portfolio average'
  const averageValues = calculateAverageValues(namedGistms)
  const result = [
    { name: 'Topic 1', [portfolioAverage]: averageValues.topic1 },
    { name: 'Topic 2', [portfolioAverage]: averageValues.topic2 },
    { name: 'Topic 3', [portfolioAverage]: averageValues.topic3 },
    { name: 'Topic 4', [portfolioAverage]: averageValues.topic4 },
    { name: 'Topic 5', [portfolioAverage]: averageValues.topic5 },
    { name: 'Topic 6', [portfolioAverage]: averageValues.topic6 },
  ]
  return (
    <div style={{ height: adjustedHeight, width: '100%' }}>
      <Typography variant="h6" align="center">
        {'GISTM Compliance Ratings - Portfolio'}
      </Typography>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={result}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis angle={90} domain={[0, 4]} tickCount={5} />
          <Radar
            dataKey={portfolioAverage}
            name={portfolioAverage}
            isAnimationActive={true}
            stroke={'#3B2175'}
            fill={'#3B2175'}
            style={{ transition: 'opacity .3s ease-in-out', opacity: 1 }}
            fillOpacity={0.6}
          />

          <Tooltip />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  )
}
