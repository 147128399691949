import React from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'
import { themeObject } from '../../muiTheme'
import { DialogFrameChildrenProps } from './common/DialogFrame'
import { adjustedHeightConstant } from '../Layout/BasicLayoutComponents'
import { ScientificTooltip } from './common/ScientificTooltip'
import { useDamsWithResults } from '../../hooks/useDamsWithResults'
import { useRecoilValue } from 'recoil'
import { selectedDamIdState } from '../../store/dams'

const colors = themeObject.palette.charts

export const FparPieChart: React.FC<DialogFrameChildrenProps> = ({ componentHeight = 350 }) => {
  const adjustedHeight = componentHeight - adjustedHeightConstant
  const { enhancedDams } = useDamsWithResults()
  const selectedDams = useRecoilValue(selectedDamIdState)

  const modifiedDams =
    selectedDams.length > 0 ? selectedDams.map(id => ({ ...(enhancedDams.find(d => d.id == id) || {}) })) : enhancedDams
  let total = 0
  for (let dam of modifiedDams) {
    total += dam?.pof.totalPof || 0
  }

  const onePercent = total / 100
  const pieRows = modifiedDams.map(d => ({ name: d.name, risk: (d?.pof.totalPof || 0) / onePercent }))

  return (
    <div style={{ height: adjustedHeight, width: '100%' }}>
      <ResponsiveContainer width="100%" height={adjustedHeight}>
        <PieChart width={adjustedHeight} height={adjustedHeight}>
          <Pie
            isAnimationActive={false}
            data={pieRows}
            cx="50%"
            cy="50%"
            labelLine={false}
            fill="#8884d8"
            dataKey="risk"
          >
            {pieRows.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Legend />
          <Tooltip content={<ScientificTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}
