import React from 'react'
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup, Point } from 'react-simple-maps'
import { Pin } from './Pin'
import { themeObject } from '../../../muiTheme'
import { ButtonGroup, Button, Icon } from '@material-ui/core'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { damState, selectedDamIdState, Dam, lastSelectedDamId } from 'src/store/dams'

const geoUrl = 'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json'

type Position = { coordinates: Point; zoom: number }
interface Props {
  height?: number
}
export const DamMap: React.FC<Props> = ({height}) => {
  const [position, setPosition] = React.useState<Position>({
    coordinates: [9.4887348, 43.7102889],//[132.5742550640839, -25.498513971945872], //[133.16249453157758, -26.14841837162921],
    zoom: 6,
  })
  const dams = useRecoilValue(damState)
  const [selectedDamIds, setSelectedDamIds] = useRecoilState(selectedDamIdState)
  const lastSelDamId = useSetRecoilState(lastSelectedDamId)
  const handleZoomIn = () => {
    if (position.zoom > 5) return setPosition(pos => ({ ...pos, zoom: 6.5 }))
    setPosition(pos => ({ ...pos, zoom: pos.zoom * 2 }))
  }

  const handleZoomOut = () => {
    setPosition(pos => ({ ...pos, zoom: position.zoom <= 1 ? 1 : pos.zoom / 2 }))
  }

  const handleMoveEnd = (position: Position) => {
    setPosition(position)
  }

  const toggleSelectDam = (dam: Dam, isSelected: boolean) => () => {
    if (isSelected) {
      setSelectedDamIds(selectedDamIds.filter(id => id !== dam.id))
      return
    }
    setSelectedDamIds([...selectedDamIds, dam.id])
    lastSelDamId(dam.id)
  }

  return (
    <div style={{ position: 'relative', height: height || '100%' }}>
      <ComposableMap projection="geoMercator">
        <ZoomableGroup zoom={position.zoom} center={position.coordinates} onMoveEnd={handleMoveEnd}>
          <Geographies geography={geoUrl}>
            {({ geographies }) => (
              <>
                {geographies.map(geo => (
                  <Geography
                    key={geo.rsmKey}
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none' },
                      pressed: { outline: 'none' },
                    }}
                    stroke="#fff"
                    geography={geo}
                    fill={themeObject.palette.secondary.darklight}
                  />
                ))}
                {dams.map(dam => {
                  const isSelected = Boolean(selectedDamIds.find(id => id === dam.id))
                  return (
                    <>{/* TODO COORDINATES */}
                    <Marker key={dam.id} coordinates={[123.2186809, -21.9586286]} onClick={toggleSelectDam(dam, isSelected)}>
                      <Pin dam={dam} zoom={position.zoom} selected={isSelected} />
                    </Marker></>
                  )
                })}
              </>
            )}
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
        <ButtonGroup size="small" color="primary" aria-label="small outlined button group">
          <Button onClick={handleZoomIn}>
            <Icon>addCircleOutline</Icon>
          </Button>
          <Button onClick={handleZoomOut}>
            <Icon>removeCircleOutline</Icon>
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}
