import React from 'react'
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { themeObject } from '../../muiTheme'
import { useRecoilValue } from 'recoil'
import { damState } from '../../store/dams'
import { ScientificTooltip } from './common/ScientificTooltip'
import { ScientificTickNumber } from './common/ScientificTickNumber'
import { DialogFrameChildrenProps } from './common/DialogFrame'
import { adjustedHeightConstant } from '../Layout/BasicLayoutComponents'
import { useDamsWithResults } from '../../hooks/useDamsWithResults'

export const FparRiskSummaryChart: React.FC<DialogFrameChildrenProps> = ({ componentHeight = 350 }) => {
  const { enhancedDams } = useDamsWithResults()

  const adjustedHeight = componentHeight - adjustedHeightConstant

  const rows = enhancedDams.map(d => ({ name: d.name, risk: d.pof.totalPof }))

  return (
    <div style={{ height: adjustedHeight, width: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={rows}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis scale="log" tick={<ScientificTickNumber />} domain={[0.00000000001, 1]} angle={90} />
          <Radar
            name={'f-PAR Risk Summary'}
            dataKey={'risk'}
            stroke={themeObject.palette.secondary.main}
            fill={themeObject.palette.secondary.main}
            fillOpacity={0.5}
          />
          <Tooltip content={<ScientificTooltip />} />
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  )
}
