import { WhiteListItemIcon } from './WhiteListItemIcon'
import { Collapse, createStyles, List, ListItemText, makeStyles, Theme } from '@material-ui/core'
import { ListItem } from '@material-ui/core'
import React from 'react'
import Icon from '@material-ui/core/Icon'
import { useHistory, useLocation } from 'react-router-dom'
import { ExpandLess, ExpandMore, StarBorder } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(6.5),
    },
    itemIcon: {
      minWidth: '36px',
    },
  })
)

interface Props {
  label: string
  childMenus?: Props[]
  icon?: string
  path?: string
  className?: string
}

export const MainNavigationMenuItem: React.FC<Props> = ({
  children,
  icon,
  label,
  path,
  childMenus,
  className,
  ...rest
}) => {
  const hasChildren = Array.isArray(childMenus)
  const classes = useStyles()
  const history = useHistory()
  const [open, setOpen] = React.useState(true)
  const [selected, setSelected] = React.useState(false)
  const location = useLocation()

  React.useEffect(() => {
    setSelected(location.pathname === path)
  }, [location])

  const onItemClick = React.useCallback(() => {
    path && history.push(path)
  }, [path, history])

  const toggleNestedMenus = React.useCallback(() => {
    setOpen(!open)
  }, [setOpen, open])

  return (
    <>
      <ListItem
        selected={selected}
        className={className}
        button
        onClick={hasChildren ? toggleNestedMenus : onItemClick}
        {...rest}
      >
        {icon && (
          <WhiteListItemIcon className={classes.itemIcon}>
            <Icon>{icon}</Icon>
          </WhiteListItemIcon>
        )}
        <ListItemText primary={label} />
        {children}
        {hasChildren && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
      </ListItem>
      {Array.isArray(childMenus) && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {childMenus.map((child, index) => (
              <MainNavigationMenuItem key={`${index}|${child.path}`} className={classes.nested} {...child} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}
