export function isAccessTokenValid(accessToken: string | null) {
  if (!accessToken) return false

  const jwtParts = accessToken.split('.')
  if (jwtParts.length !== 3) return false

  try {
    const payloadJson = JSON.parse(atob(jwtParts[1]))
    const expirationTime = payloadJson.exp
    const currentTime = Math.floor(Date.now() / 1000)

    if (currentTime < expirationTime) {
      return true
    }
  } catch (error) {
    console.error('Error decoding access token', error)
  }

  return false
}
