import React, { useCallback, useEffect } from 'react'
import { MainContainer, LeftContainer, RightContainer, leftNavBarWidth } from './BasicLayoutComponents'
import { MainNavigation } from '../Main/MainNavigation/MainNavigation'
import { useSetRecoilState } from 'recoil'
import { cardDimensionsState } from '../../store/layout'
import {useMsal} from "@azure/msal-react";

interface Props {
  leftNavigation?: React.ReactNode
}

export const LeftTopToolbarLayout: React.FC<Props> = ({ children, leftNavigation = <MainNavigation /> }) => {
  const setDimensions = useSetRecoilState(cardDimensionsState)

  const handleResize = useCallback(() => {
    setDimensions({
      width: (window.innerWidth - leftNavBarWidth) / 3,
      height: window.innerHeight / 2 - 60,
    })
  }, [setDimensions])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const { instance, accounts } = useMsal();

  /*const getRoles = () => {
    if (accounts.length > 0) {
      const account = instance.getAccount(accounts[0]);
      if (account) {
        const roles = account.idTokenClaims['roles'];
        return roles;
      }
    }
    return [];
  };*/

  useEffect(() => {
    handleResize()
  }, [handleResize])

  return (
    <MainContainer>
      <LeftContainer>{leftNavigation}</LeftContainer>
      <RightContainer>{children}</RightContainer>
    </MainContainer>
  )
}
