import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { DataGrid, GridColDef, GridSelectionModelChangeParams } from '@material-ui/data-grid'
import { Paper } from '@material-ui/core'
import { cardDimensionsState } from '../../store/layout'
import { criticalControlsState, selectedCriticalControlIdState } from 'src/store/criticalControls'

export const CriticalControlsSelectedDamGrid = () => {
  const cardDimensions = useRecoilValue(cardDimensionsState)
  const criticalControls = useRecoilValue(criticalControlsState)
  const [selectedCriticalControlId, setSelectedCriticalControl] = useRecoilState(selectedCriticalControlIdState)

  const adjustedHeight = cardDimensions.height + 30

  const updateSelection = (newSelection: GridSelectionModelChangeParams) => {
    for (const selId of newSelection.selectionModel) {
      if (selId !== selectedCriticalControlId) {
        setSelectedCriticalControl(selId as string)
        return
      }
    }
  }

  const rows = criticalControls

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Facility', width: 300 },
    {
      field: 'failureMode',
      headerName: 'Failure Mode',
      width: 300,
    },
    {
      field: 'criticalControl',
      headerName: 'Critical Control',
      width: 300,
    },
    {
      field: 'averageRating',
      headerName: 'Critical Control Average Rating',
      width: 300,
    },
  ]

  return (
    <Paper>
      <div style={{ height: adjustedHeight, width: '100%' }}>
        <DataGrid
          checkboxSelection={true}
          onSelectionModelChange={updateSelection}
          hideFooter
          selectionModel={selectedCriticalControlId ? [selectedCriticalControlId] : undefined}
          rows={rows}
          columns={columns}
        />
      </div>
    </Paper>
  )
}
