import { Answer, answersState, createEmptyAnswer, Question, QuestionTypes } from '../../store/questions'
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuestionsApi } from '../../hooks/api/useQuestionsApi'
import { useSetRecoilState } from 'recoil'
import { DialogEditQuestion } from './DialogEditQuestion'

const maxLength = 143
interface Props {
  question: Question
  damId: number
  givenAnswer?: Answer
}
export const RenderQuestion: React.FC<Props> = ({ question, givenAnswer, damId }) => {
  const setAnswers = useSetRecoilState(answersState)
  const [answer, setStateAnswer] = useState(givenAnswer)

  const { setAnswer } = useQuestionsApi()

  const handleAnswerSave = useCallback(
    (newAnswer: Answer) => () => {
      setAnswer(newAnswer, damId)
      setAnswers(old => [...old.filter(a => a.questionId !== newAnswer.questionId), newAnswer])
    },
    [setAnswer]
  )

  useEffect(() => {
    if (!givenAnswer) {
      return setStateAnswer(createEmptyAnswer(question.id, damId))
    }
    return setStateAnswer(givenAnswer)
  }, [givenAnswer])

  if (!answer || !question) return null

  const label = `${question.originalQuestionId}. ${question.questionText}`

  return (
    <div style={{ position: 'relative' }}>
      {question.questionText.length > maxLength && (
        <Typography style={{ color: '#757575', marginTop: 15, marginBottom: -2 }}>{label}</Typography>
      )}
      {question.type === QuestionTypes.NUMBER && (
        <FormControl fullWidth>
          <TextField
            label={question.questionText.length > maxLength ? undefined : label}
            name={`question-label-${question.id}`}
            type="number"
            value={answer.value}
            onChange={e => setStateAnswer({ ...answer, value: e.target.value })}
            onBlur={handleAnswerSave(answer)}
          />
        </FormControl>
      )}
      {question.type === QuestionTypes.ONE_OF && (
        <FormControl fullWidth>
          {question.questionText.length <= maxLength && (
            <InputLabel id={`question-label-${question.id}`}>{label}</InputLabel>
          )}
          <Select value={answer.choiceId || -1} labelId={`question-label-${question.id}`}>
            {question.options.map(o => (
              <MenuItem
                onClick={() => {
                  const newAnswer = { damId, questionId: question.id, value: null, choiceId: o.id }
                  setStateAnswer(newAnswer)
                  handleAnswerSave(newAnswer)()
                }}
                key={o.id}
                value={o.id}
              >
                {o.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {question.type === QuestionTypes.TEXT && (
        <FormControl fullWidth>
          <TextField
            label={question.questionText.length > maxLength ? undefined : label}
            name={`question-label-${question.id}`}
            value={answer.value}
            onChange={e => setStateAnswer({ ...answer, value: e.target.value })}
            onBlur={handleAnswerSave(answer)}
          />
        </FormControl>
      )}
      <DialogEditQuestion question={question} damId={damId} answer={answer} />
    </div>
  )
}
