import React, { useCallback } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import { LockOutlined } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router-dom'
import { Pages } from 'src/router'
import { useStyles } from './styles'
import {Button, ButtonGroup, Link} from '@material-ui/core'
import {useMsal} from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import styled from "styled-components";
import axios from "axios";

const MicrosoftIcon = styled.div`
  background-image: url("/microsoft_ico.png");
  background-size: 20px auto;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
`

export const Login = () => {
  const classes = useStyles()
  const history = useHistory()

  const authCallBackHandler = useCallback(
    (err, data) => {
      history.push(Pages.fC)
    },
    [history]
  )

  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error("LoginError:", e);
    });
  };

  return (
    <Container className={classes.container} component="main" maxWidth="xs">
      <div className={classes.wrapper}>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlined />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate>
            <ButtonGroup
                orientation="vertical"
                aria-label="vertical contained button group"
            >
              <Button startIcon={<MicrosoftIcon/>} onClick={() => handleLogin("popup")}>Sign in with Microsoft</Button>
            </ButtonGroup>
          </form>
        </div>
        <Box mt={8}>
          <Typography variant="body2" color="textPrimary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
              RAMP
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
          </Typography>
        </Box>
      </div>
    </Container>
  )
}
