import { Tooltip } from '@material-ui/core'
import React from 'react'
import { themeObject } from 'src/muiTheme'
import { Dam } from 'src/store/dams'

interface Props {
  selected?: boolean
  zoom?: number
  dam: Dam
}

export const Pin: React.FC<Props> = ({ selected, zoom = 1, dam }) => {
  const pinColor = selected ? themeObject.palette.primary.main : themeObject.palette.secondary.mustsee
  const dimension = 34 * (1 / zoom)
  return (
    <svg
      height={`${dimension}pt`}
      x={`${-12 / zoom}pt`}
      y={`${-28 / zoom}pt`}
      viewBox={`-119 -21 682 682.66669`}
      width={`${dimension}pt`}
      style={{
        cursor: 'pointer',
      }}
    >
      <Tooltip title={dam.name}>
        <path
          fill={pinColor}
          d="m216.210938 0c-122.664063 0-222.460938 99.796875-222.460938 222.460938 0 154.175781 222.679688 417.539062 222.679688 417.539062s222.242187-270.945312 222.242187-417.539062c0-122.664063-99.792969-222.460938-222.460937-222.460938zm67.121093 287.597656c-18.507812 18.503906-42.8125 27.757813-67.121093 27.757813-24.304688 0-48.617188-9.253907-67.117188-27.757813-37.011719-37.007812-37.011719-97.226562 0-134.238281 17.921875-17.929687 41.761719-27.804687 67.117188-27.804687 25.355468 0 49.191406 9.878906 67.121093 27.804687 37.011719 37.011719 37.011719 97.230469 0 134.238281zm0 0"
        />
      </Tooltip>
    </svg>
  )
}
