import { ValueFormatterParams } from '@material-ui/data-grid'
import { Dam } from '../store/dams'

export const damToScientificNotation = (dam: any) => {
  const newDam: any = { ...dam }
  if (dam.cof)
    for (const [key, value] of Object.entries(dam.cof)) {
      // @ts-ignore
      newDam[key] = value.toExponential(2)
    }
  if (dam.pof)
    for (const [key, value] of Object.entries(dam.pof)) {
      // @ts-ignore
      newDam[key] = value.toExponential(2)
    }
  return dam as Dam
}

export const damsToScientificNotation = (dams: Dam[]) => dams.map(d => damToScientificNotation(d)) as Dam[]

export const scientificValueFormatter = (params: ValueFormatterParams) => params.value && Number(params.value) && Number(params.value).toExponential(2)

export const totalPofValueScientificFormated = (params: ValueFormatterParams) => {
  if (typeof params.value === 'number')
    return params.value && Number(params.value) && Number(params.value).toExponential(2)
  if (typeof params.value === 'string')
    return params.value && Number(params.value) && Number(params.value).toExponential(2)
  if (typeof params.value === 'object')
    // @ts-ignore
    return params.value?.totalPof && Number(params.value?.totalPof) && Number(params.value?.totalPof).toExponential(2)
}
