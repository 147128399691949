import { atom, selector } from 'recoil'
import { HRFailureMode } from './hierarchy'
import { selectedCriticalControlState } from './criticalControls'

export enum FailureCommonValues {
  Failure = 'FAILURE',
  Ok = 'OK',
}

export enum FailureModeIds {
  SFU = 'SFU',
  SFD = 'SFD',
  SFS = 'SFS',
  PEM = 'PEM',
  PFO = 'PFO',
  PEF = 'PEF',
  OTF = 'OTF',
}

const data: HRFailureMode[] = [
  {
    id: FailureModeIds.SFU,
    name: FailureModeIds.SFU,
    height: 320,
    children: [
      {
        id: FailureModeIds.SFU + '-1',
        name: FailureModeIds.SFU + '-1',
        children: [
          {
            id: FailureModeIds.SFU + '-2',
            name: FailureModeIds.SFU + '-2',
            children: [
              {
                id: FailureModeIds.SFU + '-3',
                name: FailureModeIds.SFU + '-3',
                children: [
                  {
                    id: FailureModeIds.SFU + '3Fail',
                    name: 'FAILURE',
                  },
                  {
                    id: FailureModeIds.SFU + '3Ok',
                    name: 'OK',
                  },
                ],
              },
              {
                id: FailureModeIds.SFU + '2Ok',
                name: 'OK',
              },
            ],
          },
          {
            id: FailureModeIds.SFU + '1Ok',
            name: 'OK',
          },
        ],
      },
    ],
  },
  {
    id: FailureModeIds.SFD,
    name: FailureModeIds.SFD,
    height: 380,
    children: [
      {
        id: FailureModeIds.SFD + '-1A',
        name: FailureModeIds.SFD + '-1A',
        children: [
          {
            id: FailureModeIds.SFD + '-2A',
            name: FailureModeIds.SFD + '-2A',
            children: [
              {
                id: FailureModeIds.SFD + '-2AFail',
                name: 'FAILURE',
              },
              {
                id: FailureModeIds.SFD + '-2AOk',
                name: 'OK',
              },
            ],
          },
          {
            id: FailureModeIds.SFD + '-1AOk',
            name: 'OK',
          },
        ],
      },
      {
        id: FailureModeIds.SFD + '-1B',
        name: FailureModeIds.SFD + '-1B',
        children: [
          {
            id: FailureModeIds.SFD + '-2B',
            name: FailureModeIds.SFD + '-2B',
            children: [
              {
                id: FailureModeIds.SFD + '-2BFail',
                name: 'FAILURE',
              },
              {
                id: FailureModeIds.SFD + '-2BOk',
                name: 'OK',
              },
            ],
          },
          {
            id: FailureModeIds.SFD + '-1BOk',
            name: 'OK',
          },
        ],
      },
    ],
  },
  {
    id: FailureModeIds.SFS,
    name: FailureModeIds.SFS,
    height: 355,
    children: [
      {
        id: FailureModeIds.SFS + '-1',
        name: FailureModeIds.SFS + '-1',
        children: [
          {
            id: FailureModeIds.SFS + '-2A',
            name: FailureModeIds.SFS + '-2A',
            children: [
              {
                id: FailureModeIds.SFS + '-3A',
                name: FailureModeIds.SFS + '-3A',
                children: [
                  {
                    id: FailureModeIds.SFS + '-3AFail',
                    name: 'FAILURE',
                  },
                  {
                    id: FailureModeIds.SFS + '-3AOk',
                    name: 'OK',
                  },
                ],
              },
              {
                id: FailureModeIds.SFS + '-2AOk',
                name: 'OK',
              },
            ],
          },
          {
            id: FailureModeIds.SFS + '-2B',
            name: FailureModeIds.SFS + '-2B',
            children: [
              {
                id: FailureModeIds.SFS + '-3B',
                name: FailureModeIds.SFS + '-3B',
                children: [
                  {
                    id: FailureModeIds.SFS + '-3BFail',
                    name: 'FAILURE',
                  },
                  {
                    id: FailureModeIds.SFS + '-3BOk',
                    name: 'OK',
                  },
                ],
              },
              {
                id: FailureModeIds.SFS + '-2BOk',
                name: 'OK',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: FailureModeIds.PEM,
    name: FailureModeIds.PEM,
    height: 270,
    children: [
      {
        id: FailureModeIds.PEM + '-1',
        name: FailureModeIds.PEM + '-1',
        children: [
          {
            id: FailureModeIds.PEM + '-2',
            name: FailureModeIds.PEM + '-2',
            children: [
              {
                id: FailureModeIds.PEM + '-3',
                name: FailureModeIds.PEM + '-3',
                children: [
                  {
                    id: FailureModeIds.PEM + '-3Fail',
                    name: 'FAILURE',
                  },
                  {
                    id: FailureModeIds.PEM + '-3Ok',
                    name: 'OK',
                  },
                ],
              },
              {
                id: FailureModeIds.PEM + '-2Ok',
                name: 'OK',
              },
            ],
          },
          {
            id: FailureModeIds.PEM + '-1Ok',
            name: 'OK',
          },
        ],
      },
    ],
  },
  {
    id: FailureModeIds.PFO,
    name: FailureModeIds.PFO,
    height: 270,
    children: [
      {
        id: FailureModeIds.PFO + '-1',
        name: FailureModeIds.PFO + '-1',
        children: [
          {
            id: FailureModeIds.PFO + '-2',
            name: FailureModeIds.PFO + '-2',
            children: [
              {
                id: FailureModeIds.PFO + '-3',
                name: FailureModeIds.PFO + '-3',
                children: [
                  {
                    id: FailureModeIds.PFO + '-3Fail',
                    name: 'FAILURE',
                  },
                  {
                    id: FailureModeIds.PFO + '-3Ok',
                    name: 'OK',
                  },
                ],
              },
              {
                id: FailureModeIds.PFO + '-2Ok',
                name: 'OK',
              },
            ],
          },
          {
            id: FailureModeIds.PFO + '-1Ok',
            name: 'OK',
          },
        ],
      },
    ],
  },
  {
    id: FailureModeIds.PEF,
    name: FailureModeIds.PEF,
    height: 270,
    children: [
      {
        id: FailureModeIds.PEF + '-1',
        name: FailureModeIds.PEF + '-1',
        children: [
          {
            id: FailureModeIds.PEF + '-2',
            name: FailureModeIds.PEF + '-2',
            children: [
              {
                id: FailureModeIds.PEF + '-3',
                name: FailureModeIds.PEF + '-3',
                children: [
                  {
                    id: FailureModeIds.PEF + '-3Fail',
                    name: 'FAILURE',
                  },
                  {
                    id: FailureModeIds.PEF + '-3Ok',
                    name: 'OK',
                  },
                ],
              },
              {
                id: FailureModeIds.PEF + '-2ok',
                name: 'OK',
              },
            ],
          },
          {
            id: FailureModeIds.PEF + '-1Ok',
            name: 'OK',
          },
        ],
      },
    ],
  },
  {
    id: FailureModeIds.OTF,
    name: FailureModeIds.OTF,
    height: 355,
    children: [
      {
        id: FailureModeIds.OTF + '-1A',
        name: FailureModeIds.OTF + '-1A',
        children: [
          {
            id: FailureModeIds.OTF + '-2A',
            name: FailureModeIds.OTF + '-2A',
            children: [
              {
                id: FailureModeIds.OTF + '-2AFail',
                name: 'FAILURE',
              },
              {
                id: FailureModeIds.OTF + '-2AOk',
                name: 'OK',
              },
            ],
          },
          {
            id: FailureModeIds.OTF + '-1AOk',
            name: 'OK',
          },
        ],
      },
      {
        id: FailureModeIds.OTF + '-1B',
        name: FailureModeIds.OTF + '-1B',
        children: [
          {
            id: FailureModeIds.OTF + '-2B',
            name: FailureModeIds.OTF + '-2B',
            children: [
              {
                id: FailureModeIds.OTF + '-2BFail',
                name: 'FAILURE',
              },
              {
                id: FailureModeIds.OTF + '-2BOk',
                name: 'OK',
              },
            ],
          },
          {
            id: FailureModeIds.OTF + '-1BOk',
            name: 'OK',
          },
        ],
      },
    ],
  },
]

export const failureModeTreesState = atom({
  key: 'failureModeTreesState',
  default: data as HRFailureMode[],
})

export const selectedFailureCriticalControlState = selector({
  key: 'selectedFailureCriticalControlState',
  get: ({ get }) =>
    get(failureModeTreesState).find(c => c.id === get(selectedCriticalControlState)?.failureModeId.substring(0, 3)),
})
