import styled from 'styled-components'

export const leftNavBarWidth = 165
export const adjustedHeightConstant = 63

export const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
`
export const LeftContainer = styled.div`
  width: ${leftNavBarWidth}px !important;
  min-width: ${leftNavBarWidth}px !important;
  max-width: ${leftNavBarWidth}px !important;
  height: 100%;
`

export const RightContainer = styled.div`
  flex-grow: 1;
  height: 100%;
`
