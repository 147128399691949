import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { DamMap } from './DamMap'
import { Paper } from '@material-ui/core'
import { useRecoilValue } from 'recoil'
import { cardDimensionsState } from '../../../store/layout'

interface Props {
  top?: ReactNode
  bottom?: ReactNode
}

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  position: relative;
`

const Top = styled.div`
  height: 42px;
  margin: 0px 20px 10px 20px;
`

const MapPaper = styled(Paper)`
  height: 100%;
  margin: 0px 20px 10px 20px;
  overflow: hidden;
`

export const damMapWithActionsSpringConfig = { opacity: 1, height:'100%', from: { opacity: 0, height:'1%' } }

export const DamMapWithActions: React.FC<Props> = ({ top, bottom }) => {
  const dimensions = useRecoilValue(cardDimensionsState)
  const maxHeight = dimensions.height
  const minHeight = maxHeight <= 250
  const calculatedHeight = minHeight ? 250 : dimensions.height - 30
  return (
    <MapWrapper>
      <div style={{ flexGrow: 1 }}>
        {!minHeight && <Top>{top}</Top>}
        <MapPaper style={{ maxHeight: calculatedHeight }}>
          <DamMap height={calculatedHeight} />
        </MapPaper>
      </div>
    </MapWrapper>
  )
}
