import React, { useState } from 'react'
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'
import { themeObject } from '../../muiTheme'
import { useRecoilValue } from 'recoil'
import { selectedDams, selectedDamsWithResults } from '../../store/dams'
import { ScientificTickNumber } from './common/ScientificTickNumber'
import { ScientificTooltip } from './common/ScientificTooltip'
import { DialogFrameChildrenProps } from './common/DialogFrame'
import { adjustedHeightConstant } from '../Layout/BasicLayoutComponents'

const colors = themeObject.palette.charts

export const FparRiskRadarChart: React.FC<DialogFrameChildrenProps> = ({ componentHeight = 350 }) => {
  const dams = useRecoilValue(selectedDamsWithResults)

  const adjustedHeight = componentHeight - adjustedHeightConstant

  const [highlighted, setHighlighted] = useState<undefined | string>()
  const handleMouseEnter = ({ dataKey }: any) => setHighlighted(dataKey)
  const handleMouseLeave = () => setHighlighted(undefined)
  const determineOpacity = (name: string) => {
    if (!highlighted) return 1
    if (name === highlighted) return 1
    return 0.2
  }

  const rows = [
    { name: 'Steady-State: Drained' },
    { name: 'Steady-State: Undrained' },
    { name: 'Seismic' },
    { name: 'Overtopping' },
    { name: 'Embankment piping' },
    { name: 'Foundation piping' },
    { name: 'Embankment into or at foundation piping' },
  ] as any[]

  for (const dam of dams) {
    rows[6][dam.name] = dam.embankmentIntoOrAtFoundationPiping.totalPof
    rows[5][dam.name] = dam.foundationPiping.totalPof
    rows[4][dam.name] = dam.embankmentPiping.totalPof
    rows[3][dam.name] = dam.overtopping.totalPof
    rows[2][dam.name] = dam.seismic.totalPof
    rows[1][dam.name] = dam.steadyStateSlopeFailureUndrained.totalPof
    rows[0][dam.name] = dam.steadyStateSlopeFailureDrained.totalPof
  }

  return (
    <div style={{ height: adjustedHeight, width: '100%' }}>
      <ResponsiveContainer width="100%" height="100%">
        <RadarChart cx="50%" cy="50%" outerRadius="80%" data={rows}>
          <PolarGrid />
          <PolarAngleAxis dataKey="name" />
          <PolarRadiusAxis scale="log" tick={<ScientificTickNumber />} angle={90} domain={[0.00000000001, 1]} />
          {dams.map((dam, index) => {
            const color = colors[index % colors.length]
            const opacity = determineOpacity(dam.name)
            return (
              <Radar
                key={dam.id}
                isAnimationActive={true}
                name={dam.name}
                dataKey={dam.name}
                stroke={color}
                fill={color}
                style={{ transition: 'opacity .3s ease-in-out', opacity }}
                fillOpacity={0.6}
              />
            )
          })}
          <Legend onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
          <Tooltip content={<ScientificTooltip />} />
        </RadarChart>
      </ResponsiveContainer>
    </div>
  )
}
