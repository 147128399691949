import { atom, selector } from 'recoil'
import { damState, lastSelectedDamId, selectedDamIdState } from './dams'
import { FailureModeIds } from './failureModeTrees'

export interface CriticalControl {
  id: string
  name: string
  failureMode: string
  criticalControl: string
  averageRating: number
  criticalControlId: string
  failureModeId: string
  facilityId: string
}

const criticalControlDefault: CriticalControl[] = [
  {
    id: '1',
    name: 'Typical Iron Ore TSF',
    facilityId: '1',
    failureMode: 'Drained Conditions Slope Failure',
    criticalControl: 'Materials are not saturated',
    averageRating: 2.1,
    criticalControlId: '1',
    failureModeId: 'SFU-1',
  },
  /* {
    id: '2',
    name: 'Typical Iron Ore TSF',
    facilityId: '1',
    failureMode: 'Drained Conditions Slope Failure',
    criticalControl: 'Field stresses do not exceed instability line',
    averageRating: 2.6,
    criticalControlId: '1',
    failureModeId: 'SFU-1',
  }, */
  /* {
    id: 3,
    name: 'Typical Iron Ore TSF',
    rowObject: {
      facility: 'Typical Iron Ore TSF',
      failureMode: 'Drained Conditions Slope Failure',
      criticalControl: 'Contractive materials are not present',
      averageRating: 2.5,
    },
  }, */
  {
    id: '4',
    name: 'High Risk TSF',
    facilityId: '3',
    failureMode: 'Overtopping failure',
    criticalControl: 'Stored water not below maximum operating levels',
    averageRating: 3.1,
    criticalControlId: '3',
    failureModeId: 'OTF-1',
  },
  {
    id: '5',
    name: 'Best Practice TSF',
    facilityId: '2',
    failureMode: 'Piping failure: Embankment',
    criticalControl: 'Embankment material not susceptible to piping',
    averageRating: 1.2,
    criticalControlId: '4',
    failureModeId: 'PEM-1',
  },
]

export const criticalControlsState = atom({
  key: 'criticalControlsState',
  default: criticalControlDefault as CriticalControl[],
})

export const selectedCriticalControlIdState = atom({
  key: 'selectedCriticalControlId',
  default: null as null | string,
})

export const selectedCriticalControlState = selector({
  key: 'selectedCriticalControl',
  get: ({ get }) => get(criticalControlsState).find(a => a.id === get(selectedCriticalControlIdState)),
})
