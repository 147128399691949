import { getAccessToken } from './useGetAccessToken'
import { getUserId } from './getUserId'

export async function fetchProfilePicture() {
  const accessToken = getAccessToken()
  const userId = getUserId()
  const endpoint = `https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`
  const headers = new Headers()
  const bearer = `Bearer ${accessToken}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers,
  }

  const response = await fetch(endpoint, options)
  if (response.ok) {
    const blob = await response.blob()
    return URL.createObjectURL(blob)
  } else {
    console.error('Error fetching profile picture', response)
  }
  return null
}
