import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { getCalculationApi } from '../../api/calculationApi'

export const useCalculationsApi = () => {
  const getCalculations = useCallback((damId: number) => {
    getCalculationApi(damId)
        .then(response => {
          
        })
        .catch(e => console.error(e))
  }, [])

  return {
    getCalculations,
  }
}
