import React from 'react'
import { themeObject } from '../../muiTheme'
import Chart from 'react-google-charts'
import styled from 'styled-components'
import { DialogFrameChildrenProps } from '../charts/common/DialogFrame'
import { useRecoilValue } from 'recoil'
import { selectedCriticalControlsHierarchyState } from '../../store/criticalControlsHierarchy'
import { TitleCard } from '../common/TitleCard'
import { GridLoader } from 'react-spinners'
import { adjustedHeightConstant } from '../Layout/BasicLayoutComponents'

const lineOptions = {
  startPlug: 'behind',
  endPlug: 'behind',
  path: 'grid',
  color: themeObject.palette.secondary.main,
  startSocket: 'bottom',
  endSocket: 'top',
  startSocketGravity: 8,
  size: 2,
}

const ChartWrapper = styled.div`
  & .google-visualization-orgchart-linebottom {
    border-bottom: 4px solid ${themeObject.palette.primary.main};
  }

  & .google-visualization-orgchart-lineleft {
    border-left: 4px solid ${themeObject.palette.primary.main};
  }

  & .google-visualization-orgchart-lineright {
    border-right: 4px solid ${themeObject.palette.primary.main};
  }

  & .google-visualization-orgchart-linetop {
    border-top: 4px solid ${themeObject.palette.primary.main};
  }
  & .google-visualization-orgchart-node {
    border: 2px solid white;
    background: ${themeObject.palette.primary.main};
    color: white;
    font-weight: 600;
  }
`

export const CriticalControlsHierarchy: React.FC<DialogFrameChildrenProps> = ({
  componentHeight = 350,
  componentWidth = '100%',
}) => {
  const criticalControlHierarchy = useRecoilValue(selectedCriticalControlsHierarchyState)
  const height = componentHeight - adjustedHeightConstant - 30
  if (!criticalControlHierarchy)
    return (
      <TitleCard style={{ marginTop: `calc(${Math.floor(componentHeight / 2)}px - 35px)` }}>
        Select Critical Control
      </TitleCard>
    )
  return (
    <ChartWrapper>
      <div style={{ paddingTop: 30 }}>
        <Chart
          width={'100%'}
          height={'100%'}
          chartType="OrgChart"
          chartEvents={[
            {
              eventName: 'select',
              callback: ({ chartWrapper }) => {
                const chart = chartWrapper.getChart()
                const selection = chart.getSelection()
                if (selection.length === 1) {
                  const [selectedItem] = selection
                  const dataTable = chartWrapper.getDataTable()
                  const { row } = selectedItem
                  if (dataTable) {
                    const val = dataTable.getValue(row, 0)
                  }
                }
              },
            },
          ]}
          loader={
            <div
              style={{
                height: height,
                minWidth: componentWidth,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GridLoader color={themeObject.palette.primary.main} size={15} margin={10} />
            </div>
          }
          data={criticalControlHierarchy.hierarchy}
          options={{
            allowHtml: true,
          }}
          rootProps={{ 'data-testid': '1' }}
        />
      </div>
    </ChartWrapper>
  )
}
