import { useSetRecoilState } from 'recoil'
import { graphsState } from '../../store/graphs'
import { getGraphsApi } from '../../api/graphsApi'

export const useGraphsApi = () => {
  const setGraphs = useSetRecoilState(graphsState)
  const getGraphs = () =>
    getGraphsApi()
      .then(response => {
        if (Array.isArray(response.data.allFacilitiesResults)) {
          setGraphs(response.data.allFacilitiesResults)
        }
      })
      .catch(e => console.error(e))

  return {
    getGraphs,
  }
}
