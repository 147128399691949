import { LeftTopToolbarLayout } from '../../Components/Layout/LeftTopToolbarLayout'
import React from 'react'
import { Grid } from '@material-ui/core'
import { useSpring, animated } from 'react-spring'
import { FlipChart } from '../../Components/charts/common/FlipChart'
import { FlipIds } from '../../constants.ts/constants'
import { GridContainer } from '../../Components/common/styled'
import { DialogFrame } from '../../Components/charts/common/DialogFrame'
import { DamMapWithActions, damMapWithActionsSpringConfig } from '../../Components/Widgets/DamMap/DamMapWithActions'
import { MapBottomActions } from '../../Components/Dashboard/MapBottomActionts'
import { MapTopActions } from '../../Components/Dashboard/MapTopActionts'
import { CriticalControlsVertical } from '../../Components/CriticalControls/CriticalControlsVertical'
import { CriticalControlsHierarchy } from '../../Components/CriticalControls/CriticalControlHierarchy'
import { CriticalControlsSelectedDamGrid } from '../../Components/DataGrids/CriticalControlsSelectedDamGrid'

export const CriticalControlsDashboard = () => {
  const springProps = useSpring(damMapWithActionsSpringConfig)
  return (
    <LeftTopToolbarLayout>
      <GridContainer style={{ overflow: 'hidden' }}>
        <Grid container spacing={1}>
          <Grid item sm={9}>
            <CriticalControlsSelectedDamGrid />
          </Grid>
          <Grid item sm={3}>
            <animated.div style={springProps}>
              <DamMapWithActions top={<MapTopActions />} />
            </animated.div>
          </Grid>
        </Grid>
        <div style={{ height: 22 }} />
        <Grid container spacing={1}>
          <Grid item sm={4}>
            <DialogFrame>
              <CriticalControlsHierarchy />
            </DialogFrame>
          </Grid>
          <Grid item sm={4}>
            <DialogFrame>
              <CriticalControlsVertical />
            </DialogFrame>
          </Grid>
          <Grid item sm={4}>
            <DialogFrame>
              <FlipChart id={FlipIds.ScatterRisk} pages={[]} hiddenOverflow />
            </DialogFrame>
          </Grid>
        </Grid>
      </GridContainer>
    </LeftTopToolbarLayout>
  )
}
