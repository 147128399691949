import { Answer, Question, questionState, Subchapter } from '../../store/questions'
import { Typography } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { themeObject } from '../../muiTheme'
import { RenderQuestion } from './RenderQuestion'

interface Props {
  damId: number
  subchapter: Subchapter
  answerObject: Record<string, Answer>
}
export const SubchapterComponent: React.FC<Props> = ({ damId, subchapter, answerObject }) => {
  const questions = useRecoilValue(questionState)
  const filteredQuestions = questions.filter(q => q.subChapterId === subchapter.id)

  return (
    <div>
      <Typography style={{color: themeObject.palette.primary.main}} variant="h5">{subchapter.name} ({subchapter.id})</Typography>

      {filteredQuestions.map(qq => (
        <RenderQuestion damId={damId} question={qq} givenAnswer={answerObject[qq.id]} key={qq.id}/>
      ))}
    </div>
  )
}
