import axios from 'axios'
import { Dam, DamResult } from '../store/dams'

export const DAMS_URL: string = '/dams'
export const RESULT_URL: string = '/graphs'

export const getDamsApi = () => axios.get(`${process.env.REACT_APP_API_URL}${DAMS_URL}`)
export const createDamApi = (dam: Dam) => axios.post(`${process.env.REACT_APP_API_URL}${DAMS_URL}`, dam)

//TODO There should be /${damId}, NOW IT IS HARDCODED 3
export const getDamResultsApi = (damId: string) => axios.get<DamResult>(`${process.env.REACT_APP_API_URL}${RESULT_URL}/3`)
