import React from 'react'
import { useRecoilValue } from 'recoil'
import { FailureModeEventTree } from '../Trees/FailureModeEventTree'
import { selectedFailureCriticalControlState } from '../../store/failureModeTrees'
import { DialogFrameChildrenProps } from '../charts/common/DialogFrame'
import { adjustedHeightConstant } from '../Layout/BasicLayoutComponents'
import { TitleCard } from '../common/TitleCard'
import { selectedCriticalControlState } from '../../store/criticalControls'

export const CriticalControlsVertical: React.FC<DialogFrameChildrenProps> = ({
  componentHeight = 350,
  componentWidth = 450,
}) => {
  const failureModeTree = useRecoilValue(selectedFailureCriticalControlState)
  const criticalControlSelected = useRecoilValue(selectedCriticalControlState)
  let height = componentHeight - adjustedHeightConstant
  const width = componentWidth - 60

  if (height >= 150) height = height * 0.8

  if (!failureModeTree)
    return (
      <TitleCard style={{ marginTop: `calc(${Math.floor(componentHeight / 2)}px - 35px)` }}>
        Select Critical Control
      </TitleCard>
    )

  return (
    <>
      <div style={{ height, width, padding: '20px 20px 0px 20px' }}>
        <FailureModeEventTree
          highlight={criticalControlSelected?.failureModeId}
          onNodeClick={() => {}}
          data={failureModeTree}
          height={height}
          width={width}
        />
      </div>
    </>
  )
}
