import { atom } from 'recoil'

export interface GraphItemDetailedDto {
  steadyStateSlopeFailureDrained: {
    investigation: number
    testing: number
    stabilityAssessment: number
    construction: number
    operationAndMonitoring: number
    finalStructureClass: number
    totalPof: number
  }
  steadyStateSlopeFailureUndrained: {
    investigation: number
    testing: number
    stabilityAssessment: number
    construction: number
    operationAndMonitoring: number
    finalStructureClass: number
    totalPof: number
  }
  seismic: {
    investigation: number
    testing: number
    stabilityAssessment: number
    construction: number
    operationAndMonitoring: number
    finalStructureClass: number
    totalPof: number
  }
  overtopping: {
    totalPof: number
  }
  hydraulicGradient: {
    totalPof: number
  }
  embankmentPiping: {
    embankmentFilter: number
    coreGeologyOriginWeCgo: number
    coreSoilTypeWeCst: number
    compactionWeCc: number
    conduitsWeCon: number
    foundationSlopesTreatmentWeFt: number
    observationsOfSeepageWeObs: number
    monitoringAndSurveillanceWeMon: number
    finalMultiplier: number
    embankmentTypeValueForFirstFiveYears: number
    embankmentTypeValueAfterFiveYears: number
    baseLikelihoodOfFailure: number
    totalPof: number
  }
  foundationPiping: {
    filtersBlanket: number
    foundationMaterialBellowCutoff: number
    foundationCutoffType: number
    foundationCutoffTypeConstructionQualityMultiplier: number
    constructionQuality: number
    foundationGeology: number
    observationsOfSeepage: number
    observationsOfPorePressures: number
    monitoringAndSurveillance: number
    finalMultiplier: number
    baseLikelihoodOfFailure: number
    totalPof: number
  }
  embankmentIntoOrAtFoundationPiping: {
    foundationCutoffTrench: number
    foundationGeologyBelowCutoff: number
    foundationGeologyBelowDownstreamShoulder: number
    erosionControlMeasuresAtFoundationInterface: number
    groutingOfFoundations: number
    coreGeologyOriginWeCgo: number
    coreSoilTypeWeCst: number
    foundationSlopesTreatmentWeFt: number
    observationsOfSeepageWeObs: number
    monitoringAndSurveillanceWeMon: number
    finalMultiplierForCutoff: number
    finalMultiplierForDsShoulder: number
    baseLikelihoodOfFailure: number
    totalPof: number
  }
  totalPof: number
}

export interface GraphItem {
  damId: string
  created: string
  rampUserId: string
  note: string
  valid: boolean
  detailedDto: GraphItemDetailedDto
}

export const graphsState = atom({
  key: 'graphsState',
  default: [] as GraphItem[],
})
