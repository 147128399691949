import { Button, createStyles, ListItemIcon, makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { themeObject } from '../../../muiTheme'
import { fetchProfilePicture } from '../../../api/microsoft/fetchProfilePicture'
import { Avatar } from './Avatar'
import styled from 'styled-components'
import {getUser} from "../../../api/microsoft/getUserId";
import {Pages} from "../../../router";
import {useHistory} from "react-router-dom";

const ProfileWrapper = styled.div`
  display: flex;
  gap: 10px;
  color: ${themeObject.palette.text.white};
  flex-direction: column;
`

const NameDisplay = styled.div`
  text-align: center;
  margin-top: -5px;
`

const LogoutButton = styled(Button)`
  text-align: center;
  color: ${themeObject.palette.text.white};
  border-radius: 0;
`

export const ProfileMenu: React.FC = () => {
  const { instance, accounts } = useMsal()
  const history = useHistory()
  const [profPicture, setProfPicture] = useState<string | null>(null)
  const user = accounts[0]

  const handleLogout = logoutType => {
    history.push(Pages.Home)
    instance.logoutRedirect({
      postLogoutRedirectUri: process.env.REACT_APP_API_URL,
    })
  }

  if (!user) return null

  useEffect(() => {
    fetchProfilePicture().then(pic => setProfPicture(pic))
  }, [])

  return (
    <ProfileWrapper>
      {user.name && <Avatar name={user.name} />}
      <NameDisplay>{user.name}</NameDisplay>
      <NameDisplay>{getUser()?.idTokenClaims?.roles}</NameDisplay>
      <LogoutButton fullWidth variant="contained" color="secondary" onClick={handleLogout}>
        Log out
      </LogoutButton>
    </ProfileWrapper>
  )
}
