import React from 'react'
import { Group, hierarchy, Tree, LinearGradient } from '@visx/visx'
import useForceUpdate from './useForceUpdate'
import getLinkComponent from './getLinkComponent'
import { FailureCommonValues } from '../../store/failureModeTrees'
import { Spring } from 'react-spring/renderprops-universal'
import { Fragment } from 'react'
import { HierarchyTypes, TreeNode } from '../../store/hierarchy'
import './FailureModeEventTree.css'

const defaultMargin = { top: 30, left: 30, right: 30, bottom: 70 }

export type LinkTypesProps = {
  width: number
  height: number
  data: TreeNode
  onNodeClick(node: TreeNode): void
  highlight?: string
  margin?: { top: number; right: number; bottom: number; left: number }
}

export const FailureModeEventTree = ({
  width: totalWidth,
  height: totalHeight,
  margin = defaultMargin,
  data,
  onNodeClick,
  highlight,
}: LinkTypesProps) => {
  const layout = 'cartesian'
  const orientation = 'horizontal'
  const linkType = 'diagonal'
  const stepPercent = 0.5
  const forceUpdate = useForceUpdate()

  const innerWidth = totalWidth - margin.left - margin.right
  const innerHeight = totalHeight - margin.top - margin.bottom

  let origin: { x: number; y: number }
  let sizeWidth: number
  let sizeHeight: number

  origin = { x: 0, y: 0 }
  sizeWidth = innerHeight
  sizeHeight = innerWidth

  const LinkComponent = getLinkComponent({ layout, linkType, orientation })

  return totalWidth < 10 ? null : (
    <div style={{ width: totalWidth, height: totalHeight }}>
      <Spring
        config={{ mass: 1, tension: 280, friction: 60 }}
        from={{ y: 0, x: 0, a: innerWidth / 2, b: innerHeight / 2 }}
        to={{
          y: 1,
          x: 1,
          a: 0,
          b: 0,
        }}
      >
        {styles => {
          return (
            <Fragment>
              <svg width={totalWidth} height={totalHeight}>
                <g
                  opacity={styles.y}
                  transform={`scale(${styles.y}, ${styles.x}), translate(${styles.a}, ${styles.b})`}
                >
                  <LinearGradient id="links-gradient" from="#fd9b93" to="#fe6e9e" />
                  <rect width={totalWidth} height={totalHeight} rx={14} opacity={0} />
                  <Group top={margin.top} left={margin.left}>
                    <Tree
                      root={hierarchy(data, d => (d.isExpanded ? null : d.children))}
                      size={[sizeWidth, sizeHeight]}
                      separation={(a, b) => (a.parent === b.parent ? 1 : 0.5) / a.depth}
                    >
                      {tree => (
                        <Group top={origin.y} left={origin.x}>
                          {tree.links().map((link, i) => (
                            <LinkComponent
                              key={i}
                              data={link}
                              percent={stepPercent}
                              stroke="rgb(254,110,158,0.6)"
                              strokeWidth="1"
                              fill="none"
                            />
                          ))}

                          {tree.descendants().map((node, key) => {
                            const width = 55
                            const height = 35

                            let top: number
                            let left: number
                            top = node.x
                            left = node.y

                            const textColor =
                              node.data.name === FailureCommonValues.Failure ||
                              node.data.name === FailureCommonValues.Ok
                                ? 'rgba(0, 0, 0, 0.87)'
                                : node.data.type === HierarchyTypes.Portfolio
                                ? '#71248e'
                                : node.children
                                ? 'white'
                                : '#26deb0'

                            const isHighlighted = node.data.id.substring(0, 5) === highlight?.substring(0, 5)

                            return (
                              <Group top={top} left={left} key={key}>
                                {node.data.type === HierarchyTypes.Portfolio && (
                                  <circle
                                    r={20}
                                    fill="url('#links-gradient')"
                                    onClick={() => {
                                      onNodeClick(node.data)
                                    }}
                                  />
                                )}
                                {node.data.type !== HierarchyTypes.Portfolio &&
                                  (node.data.name === FailureCommonValues.Failure ? (
                                    <rect
                                      className={isHighlighted ? 'highlighted' : ''}
                                      height={height}
                                      width={width + 20}
                                      y={-height / 2}
                                      x={-width + 20 / 2}
                                      fill="#FF6562"
                                      stroke={'rgb(59, 33, 117)'}
                                      strokeWidth={1}
                                      strokeDasharray={'2,2'}
                                      strokeOpacity={0.6}
                                      rx={10}
                                      onClick={() => {
                                        onNodeClick(node.data)
                                      }}
                                    />
                                  ) : node.data.name === FailureCommonValues.Ok ? (
                                    <rect
                                      className={isHighlighted ? 'highlighted' : ''}
                                      height={height}
                                      width={width}
                                      y={-height / 2}
                                      x={-width / 2}
                                      fill="#00C07F"
                                      stroke={'rgb(59, 33, 117)'}
                                      strokeWidth={1}
                                      strokeDasharray={'2,2'}
                                      strokeOpacity={0.6}
                                      rx={10}
                                      onClick={() => {
                                        onNodeClick(node.data)
                                      }}
                                    />
                                  ) : (
                                    <rect
                                      className={isHighlighted ? 'highlighted' : ''}
                                      height={height}
                                      width={width}
                                      y={-height / 2}
                                      x={-width / 2}
                                      fill="rgb(59, 33, 117)"
                                      stroke={node.data.children ? '#03c0dc' : '#26deb0'}
                                      strokeWidth={1}
                                      strokeDasharray={node.data.children ? '0' : '2,2'}
                                      strokeOpacity={node.data.children ? 1 : 0.6}
                                      rx={node.data.children ? 4 : 10}
                                      onClick={() => {
                                        onNodeClick(node.data)
                                      }}
                                    />
                                  ))}
                                <text
                                  dy=".43em"
                                  x={node.data.name === FailureCommonValues.Failure ? -7 : 0}
                                  fontSize={10}
                                  fontFamily="Arial"
                                  textAnchor="middle"
                                  style={{ pointerEvents: 'none' }}
                                  fill={textColor}
                                >
                                  {node.data.name}
                                </text>
                              </Group>
                            )
                          })}
                        </Group>
                      )}
                    </Tree>
                  </Group>
                </g>
              </svg>
            </Fragment>
          )
        }}
      </Spring>
    </div>
  )
}
