import React from 'react'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import { MainNavigationMenuItem } from './MainNavigationMenuItem'
import { Pages } from '../../../router'
import { useStyles, Wrapper } from './MainNavigation.styles'
import { ProfileMenu } from './ProfileMenu'
import styled from 'styled-components'

const StyledList = styled(List)`
  flex-grow: 1;
`

export const MainNavigation = () => {
  const classes = useStyles()
  return (
    <Wrapper>
      <StyledList
        aria-labelledby="nested-list-subheader"
        subheader={
          <>
            <ListSubheader color="inherit" component="div" id="nested-list-subheader">
              <img
                style={{
                  display: 'inline-block',
                  height: '31px',
                  marginTop: '14px',
                  marginBottom: '-11px',
                  paddingRight: '10px',
                  marginLeft: '-1px',
                }}
                src="/raamlogo.png"
              />
              RAMP
            </ListSubheader>
          </>
        }
        className={classes.root}
      >
        <MainNavigationMenuItem
          icon="dashboard"
          label="Risk Assesment"
          childMenus={[
            { label: 'Risk Dashboard', path: Pages.fC },
            { label: 'Critical Controls', path: Pages.CriticalControls },
            //{ label: 'Event Tree', path: Pages.EventTree },
            //{ label: 'Risk Scenario Tool', path: Pages.RskScenarioTool },
          ]}
        />
        <MainNavigationMenuItem
          icon="verifiedUserOutlined"
          label="Compliance"
          childMenus={[
            { label: 'GISTM', path: Pages.GISTM },
            //{ label: 'ANCOLD', path: Pages.ANCOLD },
          ]}
        />
        <MainNavigationMenuItem
          icon="settings"
          label="Settings"
          childMenus={[
            { label: 'Facilities', path: Pages.Facilities },
            { label: 'Characteristics', path: Pages.DamCharacteristicsQuestions },
            //{ label: 'App Settings', path: Pages.ANCOLD },
          ]}
        />
      </StyledList>
      <ProfileMenu />
    </Wrapper>
  )
}
