import { atom, selector } from 'recoil'
import { criticalControlsState, selectedCriticalControlIdState, selectedCriticalControlState } from './criticalControls'

type HierarchyArray = [{ v: string; f: string } | string, string, string]

export interface CriticalControlHierarchy {
  id: string
  hierarchy: HierarchyArray[]
}

const initial: CriticalControlHierarchy[] = [
  {
    id: '1',
    hierarchy: [
      ['Name', 'Parent', 'ToolTip'],
      [
        {
          v: 'CC',
          f: 'Materials are not unexpectedly saturated',
        },
        '',
        'Critical Control',
      ],
      [
        {
          v: 'SC1',
          f: 'Appropriate design methodology',
        },
        'CC',
        'Supporting Control',
      ],
      [
        {
          v: 'SC2',
          f: 'Correct operational practices',
        },
        'CC',
        'Supporting Control',
      ],
      ['Construction deviation documentation', 'SC1', 'Supporting Characteristic'],
      ['Saturated liquefiable zones', 'SC2', 'Supporting Characteristic'],
    ],
  },
  {
    id: '2',
    hierarchy: [
      ['Name', 'Parent', 'ToolTip'],
      [
        {
          v: 'Emb',
          f: 'Embankment material not susceptible to piping\n',
        },
        '',
        'The President',
      ],
      [
        {
          v: 'Def',
          f: 'Defensive design',
        },
        'Emb',
        'VP',
      ],
      [
        {
          v: 'Well',
          f: 'Well executed construction',
        },
        'Emb',
        '',
      ],
      ['Construction QA/QC', 'Well', 'Cons'],
      ['Construction Supervision', 'Well', 'Super'],
      ['Materials specification considering piping', 'Def', 'M'],
      ['Was piping considered in the embankment design?', 'Def', 'W'],
    ],
  },
  {
    id: '3',
    hierarchy: [
      ['Name', 'Parent', 'ToolTip'],
      [
        {
          v: 'CC',
          f: 'Stored water below maximum operating levels',
        },
        '',
        'Critical Control',
      ],
      [
        {
          v: 'SC1',
          f: 'Appropriate design methodology',
        },
        'CC',
        'Supportive Control',
      ],
      [
        {
          v: 'SC2',
          f: 'Correct operational practices',
        },
        'CC',
        'Supportive Control',
      ],
      [
        {
          v: 'SCH1',
          f: 'Intensity-frequency-duration values for rare and extreme rainfall events',
        },
        'SC1',
        'Supporting Characteristic',
      ],
      [
        {
          v: 'SCH2',
          f: 'Flood study results',
        },
        'SC1',
        'Supporting Characteristic',
      ],
      [
        {
          v: 'SCH3',
          f: 'Application of exteeme rainfall events in water balance models',
        },
        'SC1',
        'Supporting Characteristic',
      ],
      [
        {
          v: 'SCH4',
          f: 'Decant pond exceeded size limits',
        },
        'SC2',
        'Supporting Characteristic',
      ],
    ],
  },
  {
    id: '4',
    hierarchy: [
      ['Name', 'Parent', 'ToolTip'],
      [
        {
          v: 'CC',
          f: 'Stored water below maximum operating levels',
        },
        '',
        'Critical Control',
      ],
      [
        {
          v: 'SC1',
          f: 'Defensive design',
        },
        'CC',
        'Supportive Control',
      ],
      [
        {
          v: 'SC2',
          f: 'Well executed construction',
        },
        'CC',
        'Supportive Control',
      ],
      [
        {
          v: 'SCH1',
          f: 'Chimney filter present',
        },
        'SC1',
        'Supporting Characteristic',
      ],
      [
        {
          v: 'SCH2',
          f: 'Design of conduits',
        },
        'SC1',
        'Supporting Characteristic',
      ],
      [
        {
          v: 'SCH3',
          f: 'Constructon technical specifications',
        },
        'SC1',
        'Supporting Characteristic',
      ],
      [
        {
          v: 'SCH4',
          f: 'Decant pond exceeded size limits',
        },
        'SC2',
        'Construction supervision',
      ],
      [
        {
          v: 'SCH5',
          f: 'Constructon technical specifications',
        },
        'SC2',
        'Supporting Characteristic',
      ],
      [
        {
          v: 'SCH6',
          f: 'Construction QA/QC',
        },
        'SC2',
        'Supporting Characteristic',
      ],
      [
        {
          v: 'SCH7',
          f: 'Construction deviation documentation',
        },
        'SC2',
        'Supporting Characteristic',
      ],
    ],
  },
]

export const criticalControlsHierarchyState = atom({
  key: 'criticalControlsHierarchyState',
  default: initial as CriticalControlHierarchy[],
})

export const selectedCriticalControlsHierarchyState = selector({
  key: 'selectedCriticalControlsHierarchyState',
  get: ({ get }) =>
    get(criticalControlsHierarchyState).find(c => c.id === get(selectedCriticalControlState)?.criticalControlId),
})
