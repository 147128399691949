import { LeftTopToolbarLayout } from '../../Components/Layout/LeftTopToolbarLayout'
import { DashboardGrid, GridContainer } from '../../Components/common/styled'
import { Button, Grid, Step, StepButton, Stepper, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Dam, damState } from '../../store/dams'
import { useQuestionsApi } from '../../hooks/api/useQuestionsApi'
import { answersState, Chapter, chapterState, questionState, subchapterState } from '../../store/questions'
import { SubchapterComponent } from '../../Components/Questions/Subchapter'
import { useDamsApi } from '../../hooks/api/useDamsApi'
import { createAnswerObjectIds } from '../../Utils/createAnswerObjectIds'
import { useCalculationsApi } from '../../hooks/api/useCalculationsApi'
import styled from 'styled-components'

const MarginButton = styled(Button)`
  margin-left: 20px;
`

export const Questions = () => {
  const dams = useRecoilValue(damState)
  const chapters = useRecoilValue(chapterState)
  const subchapters = useRecoilValue(subchapterState)
  const questions = useRecoilValue(questionState)
  const [answers, setAnswers] = useRecoilState(answersState)
  const [activeStep, setActiveStep] = React.useState<{ chapter: Chapter | null; index: number }>({
    chapter: null,
    index: 0,
  })
  const [searchString, setSearchString] = React.useState<string>('')
  const [selectedDam, setSelectedDam] = React.useState<null | Dam>(null)
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({})
  const { getChapters, getQuestions, getSubchapters, getAnswers } = useQuestionsApi()
  const { getDams, createDam } = useDamsApi()
  const { getCalculations } = useCalculationsApi()

  const answerObject = useMemo(() => createAnswerObjectIds(answers), [answers])

  const filteredSubchapters = subchapters.filter(s => s.chapterId === activeStep.chapter?.id)

  useEffect(() => {
    getDams()
    getChapters()
    getQuestions()
    getSubchapters()
  }, [])

  useEffect(() => {
    setActiveStep({ chapter: chapters[0], index: 0 })
  }, [chapters])

  const handleStep = (index: number, chapter: Chapter) => () => {
    setActiveStep({ chapter, index })
  }

  const onSelectedDamChange = useCallback((e, newValue: Dam | null) => {
    setSelectedDam(newValue)
    setAnswers([])
    newValue && getAnswers(newValue.id)
  }, [])

  return (
    <LeftTopToolbarLayout>
      <GridContainer style={{ overflow: 'hidden' }}>
        <Grid container spacing={1} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item sm={12} style={{ padding: 20, borderRadius: 2 }}>
            <Typography variant="h4" style={{ lineHeight: 1.7 }}>
              Facility's Characteristics
              <Autocomplete
                id="dams"
                style={{
                  width: 400,
                  marginLeft: 20,
                  marginTop: -10,
                  display: 'inline-block',
                  background: 'white',
                  borderRadius: 2,
                }}
                options={dams}
                value={selectedDam}
                onChange={onSelectedDamChange}
                getOptionLabel={dam => dam.name}
                onBlur={() => setTimeout(() => setSearchString(''), 600)}
                renderInput={params => {
                  return (
                    <TextField
                      value={searchString}
                      onChange={e => setSearchString(e.target.value)}
                      {...params}
                      label="Select a Facility to View or Edit"
                      variant="outlined"
                    />
                  )
                }}
              />
              {searchString.length > 2 && (
                <Button
                  onClick={async e => {
                    e.preventDefault()
                    e.stopPropagation()
                    await createDam(({
                      name: searchString,
                      siteOperation: 'created by post request',
                      country: 'Sweden',
                      city: 'Malmo',
                    } as unknown) as Dam)
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Create Dam with name: {searchString}
                </Button>
              )}
              {selectedDam && (
                <MarginButton color="secondary" variant="contained" onClick={() => getCalculations(Number(selectedDam.id))}>Calculate</MarginButton>
              )}
            </Typography>
          </Grid>

          {selectedDam && subchapters && chapters && questions ? (
            <Grid
              item
              sm={12}
              component={DashboardGrid}
              style={{
                background: 'white',
                marginTop: 10,
                borderRadius: 2,
                overflow: 'auto',
                height: 'calc(100vh - 270px)',
              }}
            >
              <div
                style={{
                  overflow: 'hidden',
                  width: 'calc(100vw - 210px)',
                  maxWidth: '100%',
                  height: 'calc(100vh - 140px)',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    overflow: 'auto',
                    maxWidth: '100%',
                    minHeight: '80px',
                  }}
                >
                  <Stepper nonLinear activeStep={activeStep.index}>
                    {chapters.map((chapter, index) => (
                      <Step key={chapter.id}>
                        <StepButton onClick={handleStep(index, chapter)} completed={completed[index]}>
                          {chapter.name}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </div>
                {Array.isArray(subchapters) && subchapters.length > 0 && (
                  <Grid style={{ overflow: 'auto', flexGrow: 1, paddingLeft: 20, paddingRight: 20 }}>
                    <Grid item xs={6}>
                      {filteredSubchapters.map((subchapter, i) => (
                        <Grid
                          style={{
                            borderRadius: 2,
                            border: '1px solid #c4c4c4',
                            margin: 10,
                            padding: 10,
                            marginBottom: 15,
                            background: i % 2 === 0 ? '#3b21750f' : undefined,
                          }}
                          key={subchapter.id}
                          item
                          xs={12}
                        >
                          <SubchapterComponent
                            damId={Number(selectedDam.id)}
                            answerObject={answerObject}
                            subchapter={subchapter}
                            key={subchapter.id}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              </div>
            </Grid>
          ) : (
            <div />
          )}
        </Grid>
      </GridContainer>
    </LeftTopToolbarLayout>
  )
}
