import { CssBaseline, ThemeProvider } from '@material-ui/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { AppRouter } from './router'
import { RecoilRoot } from 'recoil'
import { HashRouter } from 'react-router-dom'
import { muiTheme } from './muiTheme'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'
import 'src/css/beutifulDiagram.css'

export const msalInstance = new PublicClientApplication(msalConfig)

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <RecoilRoot>
        <HashRouter>
          <ThemeProvider theme={muiTheme}>
            <CssBaseline />
            <AppRouter />
          </ThemeProvider>
        </HashRouter>
      </RecoilRoot>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
