import React from 'react'
// @ts-ignore
import Plotly from 'plotly.js-dist'
import createPlotlyComponent from 'react-plotly.js/factory'
import { themeObject } from '../../muiTheme'
import { DialogFrameChildrenProps } from './common/DialogFrame'
import { adjustedHeightConstant } from '../Layout/BasicLayoutComponents'
import { useRecoilValue } from 'recoil'
import { cardDimensionsState } from '../../store/layout'

const Plot = createPlotlyComponent(Plotly)

function randArr(num: any, mul: any) {
  const arr: any = []
  const index: any = []
  for (let i = 0; i < num; i++) {
    arr.push(Math.random() * mul)
    index.push(i)
  }
  return arr
}

export const FparDollar3D: React.FC<DialogFrameChildrenProps> = ({ componentHeight = 350, componentWidth }) => {
  const adjustedHeight = componentHeight - adjustedHeightConstant
  const cardDimensions = useRecoilValue(cardDimensionsState)
  const adjustedWidth = componentWidth || cardDimensions.width
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ top: -130, left: -90, minHeight: adjustedHeight as number, width: '100%', position: 'absolute' }}>
        <Plot
          data={[
            {
              x: randArr(20, 3),
              y: randArr(20, 3),
              z: randArr(20, 3),
              mode: 'markers',
              type: 'scatter3d',
              // @ts-ignore
              scene: 'scene1',
              name: 'Lower the view point',
            },
          ]}
          layout={{
            height: adjustedHeight + 250,
            width: adjustedWidth - 20 + 230,
            showlegend: false,
            paper_bgcolor: 'rgba(0,0,0,0)',
            plot_bgcolor: 'rgba(0,0,0,0)',
          }}
          config={{
            displayModeBar: false,
          }}
        />
      </div>
    </div>
  )
}
