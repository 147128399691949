import { useSpring, animated as a } from 'react-spring'
import React, { useEffect, useRef, useState } from 'react'
import { DashboardChartGrid } from '../../common/styled'
import { GridLoader } from 'react-spinners'
import { themeObject } from '../../../muiTheme'
import { cardDimensionsState } from '../../../store/layout'
import { useRecoilValue } from 'recoil'
import { leftNavBarWidth } from '../../Layout/BasicLayoutComponents'

let store: any = { isAnimating: false, componentHeight: 350 }
let height = 0
export const useDialogFrame = () => {
  const cardDimensions = useRecoilValue(cardDimensionsState)
  const [isAnimating, setIsAnimating] = useState<boolean>(false)
  const [isPopped, setIsPopped] = useState<boolean>(false)
  const refWrapper = useRef<HTMLDivElement>(null)
  const rectWrapper = refWrapper?.current?.getBoundingClientRect()

  useEffect(() => {
    isPopped && makeSmall()
  }, [cardDimensions])

  // FIXED BUG OVERFLOWING HEIGHT
  useEffect(() => {
    isPopped && makeBig()
  }, [isPopped])

  const [props, set] = useSpring(() => ({
    zIndex: 900,
    width: '100%',
    height: 350,
    top: 0,
    left: 0,
    config: { mass: 1, tension: 500, friction: 43 },
  }))

  const maxHeight = window.innerHeight - 90
  const minWidth = cardDimensions.width - 32
  const maxWidth = window.innerWidth - leftNavBarWidth - 36

  const makeBig = () => {
    setIsAnimating(true)
    set({
      width: maxWidth,
      height: maxHeight,
      top: -Number(rectWrapper?.top || 0) + 30,
      left: -Number(rectWrapper?.left || 0) + leftNavBarWidth + 15,
      config: { mass: 1, tension: 500, friction: 43 },
      onRest: () => {
        setIsAnimating(false)
      },
    })
    setIsPopped(true)
    return
  }

  const makeSmall = () => {
    setIsAnimating(true)
    set({
      width: minWidth,
      height: cardDimensions.height,
      top: 0,
      left: 0,
      config: { mass: 1, tension: 500, friction: 27 },
      onRest: () => {
        setIsPopped(false)
        setIsAnimating(false)
      },
    })
    setIsPopped(false)
  }

  const onDoubleClick = () => {
    if (isAnimating) return
    if (!rectWrapper) return
    if (!isPopped) {
      return makeBig()
    }
    return makeSmall()
  }

  return {
    props,
    onDoubleClick,
    setIsPopped,
    refWrapper,
    isPopped,
    rectWrapper,
    isAnimating,
    cardDimensions,
    maxHeight,
    minWidth,
    maxWidth,
  }
}

export interface DialogFrameChildrenProps {
  isAnimating?: boolean
  componentHeight?: number
  componentWidth?: number
  isPopped?: boolean
}

export const DialogFrame: React.FC = ({ children }) => {
  const {
    props,
    onDoubleClick,
    isPopped,
    cardDimensions,
    isAnimating,
    refWrapper,
    maxHeight,
    minWidth,
    rectWrapper,
    maxWidth,
  } = useDialogFrame()

  let styles: any = {
    position: isAnimating || isPopped ? 'absolute' : 'static',
    minWidth: isAnimating || isPopped ? 'none' : '100% !important',
  }
  if (isAnimating || isPopped) styles = { ...styles, ...props }

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        isAnimating,
        componentHeight: isPopped ? maxHeight : cardDimensions.height,
        componentWidth: isPopped ? maxWidth : minWidth,
        isPopped,
      })
    }
    return child
  })

  return (
    <div
      ref={refWrapper}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <a.div onDoubleClick={onDoubleClick} style={styles}>
        {!isAnimating && (
          <DashboardChartGrid style={{ minHeight: cardDimensions.height, minWidth: rectWrapper?.width }}>
            {childrenWithProps}
          </DashboardChartGrid>
        )}
        {isAnimating && (
          <DashboardChartGrid
            style={{
              minHeight: cardDimensions.height,
              minWidth: rectWrapper?.width,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <GridLoader color={themeObject.palette.primary.main} size={15} margin={10} />
          </DashboardChartGrid>
        )}
      </a.div>
    </div>
  )
}
