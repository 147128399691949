import { useSetRecoilState } from 'recoil'
import { Dam, damState } from '../../store/dams'
import { createDamApi, getDamsApi } from '../../api/damsApi'

export const useDamsApi = () => {
  const setDams = useSetRecoilState(damState)
  const getDams = () =>
    getDamsApi()
      .then(response => {
        if (Array.isArray(response.data)) {
          setDams(response.data)
        }
      })
      .catch(e => console.error(e))

  const createDam = (dam: Dam) =>
    createDamApi(dam)
      .then(response => {
        setDams(old => [...old, response.data])
      })
      .catch(e => console.error(e))

  return {
    getDams,
    createDam,
  }
}
