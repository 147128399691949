import React, { CSSProperties } from 'react'

export const Avatar = ({ name, size = 30, bgColor = '#42c3c5', textColor = 'white', round = true }) => {
  const initials = name
    .split(' ')
    .map(word => word.charAt(0))
    .splice(0,2)
    .join('')
    .toUpperCase()

  const styles: CSSProperties = {
    width: `${size}px`,
    height: `${size}px`,
    lineHeight: `${size}px`,
    backgroundColor: bgColor,
    color: textColor,
    borderRadius: round ? '50%' : 0,
    textAlign: 'center',
    fontSize: `${Math.floor(size / 2)}px`,
    margin: 'auto',
  }

  return <div style={styles}>{initials}</div>
}
