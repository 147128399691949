import axios from 'axios'
import { Answer, Question, QuestionOption } from '../store/questions'

export const QUESTION_URL: string = '/question'
export const CHAPTER_URL: string = '/chapter'
export const SUBCHAPTER_URL: string = '/subchapter'
export const POSSIBLE_ANSWER: string = '/possible-answer'
export const ANSWER_URL: string = '/answer'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*'

export const getQuestionsApi = () => axios.get(`${process.env.REACT_APP_API_URL}${QUESTION_URL}`)
export const setQuestionApi = ({ id, questionText }: Question) =>
  axios.put(`${process.env.REACT_APP_API_URL}${QUESTION_URL}`, { id, questionText })

export const getChaptersApi = () => axios.get(`${process.env.REACT_APP_API_URL}${CHAPTER_URL}`)
export const getSubChaptersApi = () => axios.get(`${process.env.REACT_APP_API_URL}${SUBCHAPTER_URL}`)

export const getAnswersApi = (id: string) => axios.get(`${process.env.REACT_APP_API_URL}${ANSWER_URL}/${id}`)
export const setAnswerApi = (answer: Answer, facilityId: number) =>
  axios.post(`${process.env.REACT_APP_API_URL}${ANSWER_URL}/${facilityId}`, answer)

export const getPossibleAnswersApi = (questionId: string) =>
  axios.get(`${process.env.REACT_APP_API_URL}${POSSIBLE_ANSWER}/${questionId}`)
export const setPossibleAnswersApi = (questionId: string, possibleAnswers?: QuestionOption[]) =>
  axios.put(`${process.env.REACT_APP_API_URL}${POSSIBLE_ANSWER}`, { questionId, possibleAnswers })
