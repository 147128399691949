import { loginRequest } from '../../authConfig'
import { msalInstance } from '../../index'
import { isAccessTokenValid } from './isAccessTokenValid'
import {getUser} from "./getUserId";

let accessToken: string | null | undefined = null

export const getAccessToken = async () => {
  if (!accessToken || isAccessTokenValid(accessToken)) {
    accessToken = await retrieveAccessToken()
  }
  return accessToken
}

const retrieveAccessToken = async () => {
  const instance = msalInstance
  const account = getUser()
  if (!account) {
    console.error('No active Account!!')
    return
  }
  const accessTokenRequest = {
    ...loginRequest,
    account: account,
  }
  try {
    const response = await instance.acquireTokenSilent(accessTokenRequest)
    return response.accessToken
  } catch (error) {
    console.error('Error acquiring access token silently', error)
  }
  return null
}

export const useGetAccessToken = () => getAccessToken
