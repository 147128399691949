import { Grid } from '@material-ui/core'
import { useSpring } from 'react-spring'
import { animated } from 'react-spring'
import { DialogFrame } from 'src/Components/charts/common/DialogFrame'
import { GistmAverageRadarChart } from 'src/Components/charts/GistmAverageRadarChart'
import { GistmRadarChart } from 'src/Components/charts/GistmRadarChart'
import { MapTopActions } from 'src/Components/Dashboard/MapTopActionts'
import { DamMapWithActions, damMapWithActionsSpringConfig } from 'src/Components/Widgets/DamMap/DamMapWithActions'

import { GridContainer } from '../../Components/common/styled'
import { GistmGrid } from '../../Components/DataGrids/GistmGrid'
import { LeftTopToolbarLayout } from '../../Components/Layout/LeftTopToolbarLayout'

export const Gistm = () => {
  const springProps = useSpring(damMapWithActionsSpringConfig)
  throw "Invalid Dam property";
  return (
    <LeftTopToolbarLayout>
      <GridContainer style={{ overflow: 'hidden' }}>
        <Grid container spacing={1}>
          <Grid item sm={9}>
            <GistmGrid />
          </Grid>
          <Grid item sm={3}>
            <animated.div style={springProps}>
              <DamMapWithActions top={<MapTopActions />} />
            </animated.div>
          </Grid>
        </Grid>
        <div style={{ height: 22 }} />
        <Grid container justify="space-evenly" spacing={4}>
          <Grid item sm={4}>
            <DialogFrame>
              <GistmAverageRadarChart />
            </DialogFrame>
          </Grid>
          <Grid item sm={4}>
            <DialogFrame>
              <GistmRadarChart />
            </DialogFrame>
          </Grid>
        </Grid>
      </GridContainer>
    </LeftTopToolbarLayout>
  )
}
