import { LeftTopToolbarLayout } from '../../Components/Layout/LeftTopToolbarLayout'
import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Container,
  Paper,
  Box
} from '@material-ui/core';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface Dam {
  id: number;
  name: string;
  country: string;
  city: string;
  siteOperation?: string;
  latitude?: number;
  longitude?: number;
  deleted?: boolean;
}

export const Facilities = () => {
  const [dams, setDams] = useState<Dam[]>([]);
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedDam, setSelectedDam] = useState<Dam | null>(null);
  const [newDam, setNewDam] = useState({ name: '', country: '', city: '' });

  useEffect(() => {
    axios.get('/api/dams')
      .then(response => setDams(response.data))
      .catch(error => {
        const errorMessage = 'Error fetching dams.';
        console.error(errorMessage, error);
        toast.error(errorMessage);
      });
  }, []);

  const handleOpenDialog = (dam?: Dam) => {
    if (dam) {
      setSelectedDam(dam);
      setNewDam({ name: dam.name, country: dam.country, city: dam.city });
      setIsEditMode(true);
    } else {
      setNewDam({ name: '', country: '', city: '' });
      setIsEditMode(false);
    }
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedDam(null);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewDam({ ...newDam, [e.target.name]: e.target.value });
  };

  // Create a new (POST)
  const handleCreateDam = () => {
    axios.post('/api/dams', newDam)
      .then(response => {
        setDams([...dams, response.data]);
        handleCloseDialog();
      })
      .catch(error => {
        const errorMessage = 'Error creating dam.';
        console.error(errorMessage, error);
        toast.error(errorMessage);
      });
  };

  // Edit existing (PUT)
  const handleEditDam = () => {
    if (!selectedDam) return;

    axios.put(`/api/dams/${selectedDam.id}`, newDam)
      .then(() => {
        setDams(dams.map(dam => dam.id === selectedDam.id ? { ...dam, ...newDam } : dam));
        handleCloseDialog();
      })
      .catch(error => {
        const errorMessage = 'Error updating dam.';
        console.error(errorMessage, error);
        toast.error(errorMessage);
      });
  };

  // Delete existing (DELETE)
  const handleDeleteDam = (id: number | string) => {
    axios.delete(`/api/dams/${id}`)
      .then(() => {
        setDams(dams.filter(dam => dam.id !== id));
        toast.success('Dam successfully deleted.');
      })
      .catch(error => {
        const errorMessage = 'Error deleting dam.';
        console.error(errorMessage, error);
        toast.error(errorMessage);
      });
  };

  // Table list definitions
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'country', headerName: 'Country', width: 200 },
    { field: 'city', headerName: 'Country', width: 200 },
    {
      field: 'actions',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleOpenDialog(params.row as Dam)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteDam(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      )
    }
  ];

  return (
    <LeftTopToolbarLayout>
      <Container>
        <Paper style={{ height: 400, width: '100%' }}>
          <Box>
            <Button variant="contained" color="primary" onClick={() => handleOpenDialog()}>
              Create new facility
            </Button>
          </Box>
          <DataGrid rows={dams} columns={columns} pageSize={5} />

          <ToastContainer />

          <Dialog open={open} onClose={handleCloseDialog}>
            <DialogTitle>{isEditMode ? 'Edit existing facility' : 'Create new facility'}</DialogTitle>
            <DialogContent>
              <TextField
                label="Name"
                name="name"
                value={newDam.name}
                onChange={handleChange}
                fullWidth
                margin="dense"
              />
              <TextField
                label="Country"
                name="country"
                value={newDam.country}
                onChange={handleChange}
                fullWidth
                margin="dense"
              />
              <TextField
                label="City"
                name="city"
                value={newDam.city}
                onChange={handleChange}
                fullWidth
                margin="dense"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={isEditMode ? handleEditDam : handleCreateDam}
                color="primary"
                variant="contained"
              >
                {isEditMode ? 'Edit' : 'Create'}
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Container>
    </LeftTopToolbarLayout>
  );
};
