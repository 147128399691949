import { selector } from 'recoil'
import { atom } from 'recoil'
import { useMemo } from 'react'
import { graphsState } from './graphs'

export interface Dam {
  id: string
  name: string
  country: string
  city: string
  siteOperation: string
  latitude: 43.71
  longitude: -7.49
}

export interface DamOld {
  id: string
  name: string
  coordinates: [number, number]
  pof: {
    steadyStateSlopeFailureDrained: number
    steadyStateSlopeFailureUndrained: number
    seismic: number
    overtopping: number
    embankmentPiping: number
    foundationPiping: number
    embankmentIntoOrAtfoundationPiping: number
    totalPof: number
  }
  cof: {
    par: number
    businessConsequence: number
  }
  gistmCompliance: {
    affectedCommunities: number
    integratedKnowledgeBase: number
    designConstructionAndMonitoring: number
    managementAndGovernance: number
    emergencyResponseAndLongTermRecovery: number
    publicDisclosureAndAccessToInformation: number
  }
}

export interface DamResult {
  steadyStateSlopeFailureDrained: {
    investigation: number
    testing: number
    stabilityAssessment: number
    construction: number
    operationAndMonitoring: number
    finalStructureClass: number
    totalPof: number
  }
  steadyStateSlopeFailureUndrained: {
    investigation: number
    testing: number
    stabilityAssessment: number
    construction: number
    operationAndMonitoring: number
    finalStructureClass: number
    totalPof: number
  }
  seismic: {
    investigation: number
    testing: number
    stabilityAssessment: number
    construction: number
    operationAndMonitoring: number
    finalStructureClass: number
    totalPof: number
  }
  overtopping: {
    totalPof: number
  }
  hydraulicGradient: {
    totalPof: number
  }
  embankmentPiping: {
    embankmentFilter: number
    coreGeologyOriginWeCgo: number
    coreSoilTypeWeCst: number
    compactionWeCc: number
    conduitsWeCon: number
    foundationSlopesTreatmentWeFt: number
    observationsOfSeepageWeObs: number
    monitoringAndSurveillanceWeMon: number
    finalMultiplier: number
    embankmentTypeValueForFirstFiveYears: number
    embankmentTypeValueAfterFiveYears: number
    baseLikelihoodOfFailure: number
    totalPof: number
  }
  foundationPiping: {
    filtersBlanket: number
    foundationMaterialBellowCutoff: number
    foundationCutoffType: number
    foundationCutoffTypeConstructionQualityMultiplier: number
    constructionQuality: number
    foundationGeology: number
    observationsOfSeepage: number
    observationsOfPorePressures: number
    monitoringAndSurveillance: number
    finalMultiplier: number
    baseLikelihoodOfFailure: number
    totalPof: number
  }
  embankmentIntoOrAtFoundationPiping: {
    foundationCutoffTrench: number
    foundationGeologyBelowCutoff: number
    foundationGeologyBelowDownstreamShoulder: number
    erosionControlMeasuresAtFoundationInterface: number
    groutingOfFoundations: number
    coreGeologyOriginWeCgo: number
    coreSoilTypeWeCst: number
    foundationSlopesTreatmentWeFt: number
    observationsOfSeepageWeObs: number
    monitoringAndSurveillanceWeMon: number
    finalMultiplierForCutoff: number
    finalMultiplierForDsShoulder: number
    baseLikelihoodOfFailure: number
    totalPof: number
  }
  totalPof: number
}
type DamId = string

export const damResultsStore = atom({
  key: 'damResults',
  default: {} as Record<DamId, DamResult>,
})

export const damState = atom({
  key: 'dams',
  default: [] as Dam[],
})

export const selectedDamIdState = atom({
  key: 'selectedDamIds',
  default: [] as string[],
})

export const selectedDams = selector({
  key: 'selectedDams',
  get: ({ get }) => {
    const selectedDamIds = get(selectedDamIdState)
    return get(damState).filter(dam => selectedDamIds.find(dId => dId === dam.id))
  },
})

export const selectedDamsWithResults = selector({
  key: 'selectedDamsWithResults',
  get: ({ get }) => {
    const dams = get(selectedDams)
    const graphs = get(graphsState)
    return graphs.map(({ detailedDto, ...graphItem }) => ({
      ...graphItem,
      ...detailedDto,
      ...(dams.find(d => d.id == graphItem.damId) || {}),
    }))
  },
})

export const damsWithResults = selector({
  key: 'damsWithResults',
  get: ({ get }) => {
    const dams = get(damState)
    const graphs = get(graphsState)
    return graphs.map(({ detailedDto, ...graphItem }) => ({
      ...graphItem,
      pof: { ...detailedDto },
      ...(dams.find(d => d.id == graphItem.damId) || {}),
    }))
  },
})

export const lastSelectedDamId = atom({
  key: 'lastSelectedDamId',
  default: null as string | null,
})

export const lastSelectedDam = selector({
  key: 'lastSelectedDam',
  get: ({ get }) => {
    const selectedDamIds = get(selectedDamIdState)
    const lastSelectedDam = get(lastSelectedDamId)
    const foundSelection = selectedDamIds.find(a => a === lastSelectedDam)
    return get(damState).find(dam => foundSelection === dam.id)
  },
})
