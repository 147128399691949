import React from 'react'
import { DialogFrameChildrenProps } from '../charts/common/DialogFrame'
import { adjustedHeightConstant } from '../Layout/BasicLayoutComponents'

import fNPlot from 'src/assets/fNPlot.png'
import fNPlotS from 'src/assets/fNPlotS.png'

interface Props extends DialogFrameChildrenProps {
  src: string
  srcSmall: string,
}

export const ImageCard: React.FC<Props> = ({ componentHeight = 350, src, srcSmall, isPopped }) => {
  const adjustedHeight = componentHeight - adjustedHeightConstant
  return (
    <div
      style={{
        height: adjustedHeight as number,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        style={{ marginTop: 20, height: 'calc(100% + 40px)' }}
        src={isPopped ? src : srcSmall}
        alt={'Plot ' + src}
      />
    </div>
  )
}
