import styled from 'styled-components'
import { Paper } from '@material-ui/core'

export const DashboardGrid = styled(Paper)`
  overflow: visible;
  box-shadow: 9px 10px 7px -7px rgba(43,43,43,1);
  border: 1px solid #3b2175;
  border-radius: 1px;
  height: 100%;
`

export const DashboardChartGrid = styled(DashboardGrid)`
  padding: 18px;
`
export const GridContainer = styled.div`
  padding: 18px;
  height: 100vh;
  overflow: auto;
`