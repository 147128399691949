import React, { useEffect, useRef, useState } from 'react'
import { useSpring, animated as a } from 'react-spring'
import { FlipToBack, FlipToFront } from '@material-ui/icons'
import { Icon, IconButton } from '@material-ui/core'
import { useRecoilState, useRecoilValue } from 'recoil'
import { cardDimensionsState, flipOptionsState } from 'src/store/layout'
import { DashboardChartGrid } from '../../common/styled'
import { DialogFrameChildrenProps } from './DialogFrame'
import { GridLoader } from 'react-spinners'
import { themeObject } from '../../../muiTheme'

interface Props extends DialogFrameChildrenProps {
  id: string
  pages: React.ReactNode[]
  hiddenOverflow?: boolean
}

export const FlipChart: React.FC<Props> = ({ pages, hiddenOverflow, id, ...rest }) => {
  const [flippedState, setFlippedState] = useRecoilState(flipOptionsState)
  const cardDimensions = useRecoilValue(cardDimensionsState)
  const [isAnimating, setIsAnimating] = useState<boolean>(false)
  const currentPage = flippedState[id]?.index || 0
  const nextPage = currentPage + 1 >= pages.length ? 0 : currentPage + 1
  const wrapper = useRef<any>();
  const rectWrapper = wrapper?.current?.getBoundingClientRect()

  const config = { mass: 1, tension: 500, friction: 80 }
  const [{ opacity, transform }, set] = useSpring(() => ({
    opacity: 1,
    transform: `perspective(600px) rotateX(180deg)`,
    config,
  }))

  useEffect(() => {
    if (flippedState[id] && flippedState[id].update && !isAnimating) {
      setIsAnimating(true)
      set({
        opacity: 0,
        transform: `perspective(600px) rotateX(0deg)`,
        config,
        onRest: () => {
          setFlippedState({ ...flippedState, [id]: { index: currentPage } })
          set({
            opacity: 1,
            transform: `perspective(600px) rotateX(180deg)`,
            config: { mass: 1, tension: 500, friction: 30 },
          })
          setIsAnimating(false)
        },
      })
    }
  }, [flippedState])

  const flipCard = React.useCallback(() => {
    setIsAnimating(true)
    set({
      opacity: 0.4,
      transform: `perspective(600px) rotateX(0deg)`,
      config: { mass: 1, tension: 500, friction: 30 },
      onRest: () => {
        setFlippedState({ ...flippedState, [id]: { index: nextPage } })
        set({
          opacity: 1,
          transform: `perspective(600px) rotateX(180deg)`,
          config: { mass: 1, tension: 500, friction: 30 },
        })
        setIsAnimating(false)
      },
    })
  }, [isAnimating, setIsAnimating, setFlippedState, currentPage, nextPage, flippedState])

  const FlipIcon = React.useCallback(
    () => (
      <IconButton style={{ position: 'absolute', zIndex: 5 }} color="primary" onClick={flipCard}>
        <Icon>filter1Icon</Icon>
      </IconButton>
    ),
    [flipCard]
  )

  return (
    <div style={{ position: 'relative', overflow: isAnimating && hiddenOverflow ? 'hidden' : 'visible' }}>
      {isAnimating && (
        <a.div style={{ opacity: opacity.interpolate((o: any) => 1 - o), transform }}>
          <div
            style={{
              height: rectWrapper?.height,
              minWidth: rectWrapper?.width,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <GridLoader color={themeObject.palette.primary.main} size={15} margin={10} />
          </div>
        </a.div>
      )}
      {!isAnimating && pages[currentPage] && transform && (
        <a.div style={{ opacity, transform: transform.interpolate(t => `${t} rotateX(180deg)`) }}>
          <div ref={wrapper}>
            <FlipIcon />
            {React.cloneElement(pages[currentPage] as any, { ...rest })}
          </div>
        </a.div>
      )}
    </div>
  )
}
