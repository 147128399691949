import { atom } from 'recoil'

export interface TreeNode {
  name: string
  id: string
  parentId?: string
  fullName?: string
  isExpanded?: boolean
  children?: TreeNode[]
  width?: number
  height?: number
  type?: string
}

export interface HRFacility extends TreeNode {
  children: HRFailureMode[]
}

export interface HRFailureMode extends TreeNode {}

export interface HRProject extends TreeNode {
  children: HRFacility[]
}

export interface HRPortfolio extends TreeNode {
  name: string
  children: HRProject[]
}

export enum HierarchyTypes {
  Portfolio = 'Portfolio',
  Project = 'Project',
  Facility = 'Facility',
}

export const hierarchyPortfolioState = atom({
  key: 'hierarchyPortfolioState',
  default: {
    name: 'P',
    id: 'Portf1',
    type: HierarchyTypes.Portfolio,
    width: 200,
    height: 270,
    children: [
      {
        name: 'AUS',
        id: 'A',
        type: HierarchyTypes.Project,
        parentId: 'Portf1',
        children: [],
      },
      {
        name: 'EU',
        id: 'E',
        type: HierarchyTypes.Project,
        parentId: 'Portf1',
        children: [],
      },
      {
        name: 'US',
        id: 'U',
        type: HierarchyTypes.Project,
        parentId: 'Portf1',
        children: [],
      },
    ],
  } as HRPortfolio,
})

export const hierarchyProjectState = atom({
  key: 'hierarchyProjectState',
  default: [
    {
      name: 'US',
      id: 'U',
      type: HierarchyTypes.Project,
      height: 350,
      children: [
        {
          name: 'Best Pr.',
          id: '2',
          type: HierarchyTypes.Facility,
          parentId: 'U',
          children: [],
        },
        {
          name: 'High Risk',
          id: '3',
          type: HierarchyTypes.Facility,
          parentId: 'U',
          children: [],
        },
      ],
    },
    {
      name: 'EU',
      id: 'E',
      type: HierarchyTypes.Project,
      height: 350,
      children: [
        {
          name: 'San Ciprian',
          id: '6',
          type: HierarchyTypes.Facility,
          parentId: 'E',
          children: [],
        },
      ],
    },
    {
      name: 'AUS',
      id: 'A',
      type: HierarchyTypes.Project,
      height: 350,
      children: [
        {
          name: 'Rockfill',
          id: '4',
          type: HierarchyTypes.Facility,
          parentId: 'A',
          children: [],
        },
        {
          name: 'Typical Iron',
          id: '1',
          type: HierarchyTypes.Facility,
          parentId: 'A',
          children: [],
        },
        {
          name: 'Earthfill',
          id: '5',
          type: HierarchyTypes.Facility,
          parentId: 'A',
          children: [],
        },
      ],
    },
  ] as HRProject[],
})

export const hierarchyFacilityState = atom({
  key: 'hierarchyFacilityState',
  default: [
    {
      name: 'Rockfill',
      id: '4',
      type: HierarchyTypes.Facility,
      parentId: 'A',
      children: [],
    },
    {
      name: 'Typical Iron',
      id: '1',
      type: HierarchyTypes.Facility,
      parentId: 'A',
      children: [],
    },
    {
      name: 'Earthfill',
      id: '5',
      type: HierarchyTypes.Facility,
      parentId: 'A',
      children: [],
    },
    {
      name: 'San Ciprian',
      id: '6',
      type: HierarchyTypes.Facility,
      parentId: 'E',
      children: [],
    },
    {
      name: 'Best Pr.',
      id: '2',
      type: HierarchyTypes.Facility,
      parentId: 'U',
      children: [],
    },
    {
      name: 'High Risk',
      id: '3',
      type: HierarchyTypes.Facility,
      parentId: 'U',
      children: [],
    },
  ] as HRFacility[],
})
